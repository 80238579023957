'use client';

import type { FC, PropsWithChildren } from 'react';
import { EmbedWrapper } from '@/components/EmbedWrapper';

const onLoad = () => {
	// Call the Twitter API on this particular document fragment:
	// https://dev.twitter.com/web/javascript/initialization#init
	window.twttr?.widgets?.load();
};

interface TwitterEmbedWrapperProps extends PropsWithChildren {
	url: string;
}

export const TwitterEmbedWrapper: FC<TwitterEmbedWrapperProps> = ({
	children,
	url,
}) => (
	<EmbedWrapper
		identifier="twitter"
		onAlreadyLoaded={onLoad}
		onLoad={onLoad}
		url={url}
	>
		{children}
	</EmbedWrapper>
);
