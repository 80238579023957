import { Timer } from '@/components/raven/Numbrix/Timer';
import type {
	NumbrixGameStates,
	NumbrixGameConfig,
	NumbrixCellDefinition,
	NumbrixCellComponentRef,
} from '@/components/raven/Numbrix/definitions/NumbrixTypes';
import {
	useRef,
	type FC,
	useMemo,
	useState,
	useEffect,
	useReducer,
	useContext,
	useCallback,
	type Dispatch,
	createContext,
	type ReactNode,
	type MutableRefObject,
} from 'react';

/**
 * Define the shape of our state.
 */
interface NumbrixGameState {
	cells: NumbrixCellDefinition[];
	config: NumbrixGameConfig;
	elapsedTime: number;
	gameState: NumbrixGameStates;
}

/**
 * Define our possible actions.
 */
type Action =
	| { payload: number; type: 'TICK' }
	| { payload: NumbrixGameStates; type: 'SET_GAME_STATE' }
	| { type: 'PAUSE' }
	| { type: 'RESTART' }
	| { type: 'RESUME' }
	| { type: 'SOLVED' }
	| { type: 'SOLVING' }
	| { type: 'START' };

/**
 * Context value that we will provide.
 */
interface NumbrixGameContextProps {
	cellsRef: MutableRefObject<Record<number, null | NumbrixCellComponentRef>>;
	dispatch: Dispatch<Action>;
	handleCellBlur: (cellRef: NumbrixCellComponentRef) => void;
	handleCellFocus: (cellRef: NumbrixCellComponentRef) => void;
	handleCellMove: (eventKey: string, cellRef: NumbrixCellComponentRef) => void;
	handleCellSolved: (cellRef: NumbrixCellComponentRef) => void;
	hasHint: boolean;
	requestHint: () => void;
	requestPause: () => void;
	requestRestart: () => void;
	requestResume: () => void;
	requestSolve: () => void;
	requestStart: () => void;
	state: NumbrixGameState;
}

/**
 * Create the context.
 */
const NumbrixGameContext = createContext<NumbrixGameContextProps | undefined>(
	undefined,
);

/**
 * Define default options similar to _defaultOptions in your class.
 */
const defaultConfig: NumbrixGameConfig = {
	cellsPerRow: 9,
	date: '',
	flavor: 'Blueberry',
	level: '',
	maxCells: 81,
	solution: [],
	solveTickrate: 350,
	timerTickrate: 1000,
};

/**
 * A helper function to build cell definitions (similar to buildCells and buildCellData).
 */
const buildCells = (config: NumbrixGameConfig): NumbrixCellDefinition[] => {
	const cells: NumbrixCellDefinition[] = [];

	for (let i = 0; i < config.maxCells; i += 1) {
		const cellAnswer = config.solution[i];
		if (cellAnswer === undefined || cellAnswer === 0) {
			throw new Error(`Invalid solution value at cell index ${i}`);
		}
		cells.push({
			answer: Math.abs(cellAnswer),
			cellIndex: i,
			prefilled: cellAnswer < 0,
		});
	}

	return cells;
};

/**
 * Initialize state from the supplied config.
 */
const initState = (config: NumbrixGameConfig): NumbrixGameState => {
	const mergedConfig = { ...defaultConfig, ...config };
	return {
		cells: buildCells(mergedConfig),
		config: mergedConfig,
		elapsedTime: 0,
		gameState: 'Waiting',
	};
};

/**
 * The reducer function to handle state updates.
 */
const gameReducer = (
	state: NumbrixGameState,
	action: Action,
): NumbrixGameState => {
	switch (action.type) {
		case 'START':
			return { ...state, elapsedTime: 0, gameState: 'Active' };
		case 'PAUSE':
			return { ...state, gameState: 'Paused' };
		case 'RESUME':
			return { ...state, gameState: 'Active' };
		case 'RESTART':
			return {
				...state,
				cells: buildCells(state.config),
				elapsedTime: 0,
				gameState: 'Active',
			};
		case 'TICK':
			return { ...state, elapsedTime: state.elapsedTime + action.payload };
		case 'SOLVED':
			return { ...state, gameState: 'Complete' };
		case 'SOLVING':
			return { ...state, gameState: 'Solving' };
		case 'SET_GAME_STATE':
			return { ...state, gameState: action.payload };
		default:
			return state;
	}
};

/**
 * The Provider component that encapsulates the game state and logic.
 */
export const NumbrixGameProvider: FC<{
	children: ReactNode;
	config: NumbrixGameConfig;
}> = ({ children, config }) => {
	const [state, dispatch] = useReducer(gameReducer, config, initState);
	const timerRef = useRef<null | Timer>(null);
	const hintCell = useRef<null | NumbrixCellComponentRef>(null);
	const cellsRef = useRef<Record<number, null | NumbrixCellComponentRef>>({});
	const [hasHint, setHasHint] = useState(false);
	const cellsPerRow = state.config.cellsPerRow as number;
	const { maxCells } = state.config;
	const bottomStartingIndex = maxCells - cellsPerRow;

	// Initialize the timer once, then update elapsed time on each tick.
	useEffect(() => {
		if (!timerRef.current) {
			timerRef.current = new Timer(() => {
				dispatch({ payload: 1, type: 'TICK' });
			}, state.config.timerTickrate);
		}
		// Cleanup timer on unmount.
		return () => {
			timerRef.current?.stop();
		};
	}, [state.config.timerTickrate]);

	const requestStart = useCallback(() => {
		dispatch({ type: 'START' });
		timerRef.current?.reset();
		timerRef.current?.start();
	}, []);

	const requestPause = useCallback(() => {
		dispatch({ type: 'PAUSE' });
		timerRef.current?.stop();
	}, []);

	const requestResume = useCallback(() => {
		dispatch({ type: 'RESUME' });
		timerRef.current?.start();
	}, []);

	const requestRestart = useCallback(() => {
		dispatch({ type: 'RESTART' });
		timerRef.current?.reset();
		for (let i = 0; i <= maxCells; i += 1) {
			const cellRef = cellsRef.current[i];
			cellRef?.resetToDefault();
		}

		timerRef.current?.start();
	}, [maxCells]);

	const solvePuzzle = useCallback(
		async (afterState: NumbrixGameStates) => {
			if (state.gameState === 'Solving') {
				return;
			}

			const delay = config.solveTickrate || 750;
			const cellsByValue = state.cells.reduce(
				(acc, cell) => {
					acc[cell.answer] = cell;
					return acc;
				},
				{} as Record<number, NumbrixCellDefinition>,
			);

			// Simulate solving the puzzle
			for (let i = 1; i <= state.cells.length; i += 1) {
				const cell = cellsByValue[i];
				const cellRef = cellsRef.current[cell.cellIndex];
				cellRef?.markSolved();

				// eslint-disable-next-line no-await-in-loop
				await new Promise((resolve) => {
					setTimeout(resolve, delay);
				});
			}

			dispatch({ payload: afterState, type: 'SET_GAME_STATE' });
		},
		[config.solveTickrate, state.cells, state.gameState],
	);

	const requestSolve = useCallback(() => {
		dispatch({ type: 'SOLVING' });
		timerRef.current?.stop();
		solvePuzzle('Resigned');
	}, [solvePuzzle]);

	const requestHint = useCallback(() => {
		hintCell.current?.showHint();
	}, []);

	const handleCellBlur = useCallback(() => {
		hintCell.current = null;
		setHasHint(false);
	}, []);

	const handleCellFocus = useCallback((cellRef: NumbrixCellComponentRef) => {
		if (!cellRef.isPrefilled) {
			hintCell.current = cellRef;
			setHasHint(true);
		} else {
			hintCell.current = null;
			setHasHint(false);
		}
	}, []);

	const handleCellSolved = useCallback(() => {
		if (
			Object.values(cellsRef.current).every((cell) => cell?.filledCorrectly())
		) {
			timerRef.current?.stop();
			solvePuzzle('Complete');
		}
	}, [solvePuzzle]);

	const handleCellMove = useCallback(
		(dir: string, cellRef: NumbrixCellComponentRef) => {
			const direction = dir as 'down' | 'left' | 'right' | 'up';
			let destinationIndex: false | number = false;
			const current = cellRef.cellIndex;
			const indexRemainder = current % cellsPerRow;

			switch (direction) {
				case 'down':
					destinationIndex =
						current >= bottomStartingIndex ? false : current + cellsPerRow;
					break;
				case 'left':
					destinationIndex = indexRemainder === 0 ? false : current - 1;
					break;
				case 'right':
					destinationIndex =
						indexRemainder === cellsPerRow - 1 ? false : current + 1;
					break;
				case 'up':
					destinationIndex =
						current < cellsPerRow ? false : current - cellsPerRow;
					break;
				default:
					break;
			}

			if (destinationIndex === false) {
				// not a valid movement request
				return;
			}

			cellsRef.current[current]?.blur();
			cellsRef.current[destinationIndex]?.focus();
		},
		[cellsPerRow, bottomStartingIndex],
	);

	const value: NumbrixGameContextProps = useMemo(
		() => ({
			cellsRef,
			dispatch,
			handleCellBlur,
			handleCellFocus,
			handleCellMove,
			handleCellSolved,
			hasHint,
			requestHint,
			requestPause,
			requestRestart,
			requestResume,
			requestSolve,
			requestStart,
			state,
		}),
		[
			dispatch,
			hasHint,
			handleCellBlur,
			handleCellFocus,
			handleCellMove,
			handleCellSolved,
			requestHint,
			requestPause,
			requestRestart,
			requestResume,
			requestSolve,
			requestStart,
			state,
		],
	);

	return (
		<NumbrixGameContext.Provider value={value}>
			{children}
		</NumbrixGameContext.Provider>
	);
};

export const useNumbrixGame = (): NumbrixGameContextProps => {
	const context = useContext(NumbrixGameContext);
	if (!context) {
		throw new Error('useNumbrixGame must be used within a NumbrixGameProvider');
	}
	return context;
};

export const formatElapsedTime = (time: number): string => {
	// MM:SS
	const minutes = Math.floor(time / 60);
	const seconds = time % 60;

	return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};
