'use client';

import { useRef, type FC, useState, useEffect } from 'react';

export type JotformIframeProps = {
	formID?: string;
	url: string;
};

export const JotformIframe: FC<JotformIframeProps> = ({ formID, url }) => {
	const iframeRef = useRef<HTMLIFrameElement>(null);
	const [componentStyles, setComponentStyles] = useState<{
		border: number;
		height: number;
		minHeight?: number;
		overflow: string;
		width: string;
	}>({
		border: 0,
		height: 540,
		overflow: 'hidden',
		width: '100%',
	});

	useEffect(() => {
		const handleMessages = (content: MessageEvent) => {
			if (!content) {
				return;
			}

			if (typeof content.data !== 'string') {
				return;
			}
			const [method, value, targetForm] = content.data.split(':');

			if (formID && targetForm && targetForm !== formID) {
				return;
			}

			switch (true) {
				case method === 'setHeight':
					setComponentStyles({
						...componentStyles,
						height: parseInt(value, 10) + 60,
					});
					break;
				case method === 'setMinHeight':
					setComponentStyles({
						...componentStyles,
						minHeight: parseInt(value, 10) + 60,
					});
					break;
				case method === 'reloadPage':
					try {
						iframeRef.current?.contentWindow?.location.reload();
					} catch (e) {
						// Error handling
					}
					break;
				default:
					break;
			}
		};

		if (window.addEventListener) {
			window.addEventListener('message', handleMessages, true);
		} else if (window.attachEvent) {
			window.attachEvent('onmessage', handleMessages);
		}
		return () => {
			if (window.removeEventListener) {
				window.removeEventListener('message', handleMessages, true);
			}
		};
	}, [componentStyles, formID]);

	return (
		<iframe
			allow="geolocation; microphone; camera"
			allowFullScreen
			ref={iframeRef}
			scrolling="no"
			src={url}
			style={{
				...componentStyles,
			}}
			title="JotForm Form"
		/>
	);
};
