'use client';

import { useRef, type FC, useEffect } from 'react';
import { decodeEntities } from '@/utils/post-helpers';

interface SecureHTMLScriptProps {
	content: string;
}

export const SecureHTMLScript: FC<SecureHTMLScriptProps> = ({
	content,
}: SecureHTMLScriptProps) => {
	const ref = useRef<HTMLIFrameElement>(null);
	useEffect(() => {
		if (!ref.current) {
			return () => {};
		}

		const iframe = ref.current;
		const sizerId = '_say_sizer';
		const html = `<!DOCTYPE html><html>
                    <head></head>
                    <body
                        style="margin:0;padding:0;position:absolute;width:100%;text-align:center;">
                        <iframe id="${sizerId}" width="0" frameborder="0"
                            style="height:100%;width:100%;
                            position:absolute;top:0;bottom:0;opacity:0;right:0;left:0;z-index:-1">
                        </iframe>
                        <script>${decodeEntities(content)}</script>
                    </body>
                </html>`;

		if (!iframe.contentWindow) {
			return () => {};
		}

		// Some code to place the embed into the iframe and handle synchronization of the height of the
		// inner embed document and the host iframe that contains it.
		const innerDoc = iframe.contentWindow.document;
		innerDoc.open();
		innerDoc.write(html);
		innerDoc.close();

		const sizer = innerDoc.getElementById(sizerId) as HTMLIFrameElement | null;

		if (!sizer || !sizer.contentWindow) {
			return () => {};
		}

		const resize = () => {
			if (!sizer) {
				return;
			}

			const sizeDimensions = sizer.getClientRects()[0];
			if (sizeDimensions) {
				const sizerWidth = sizeDimensions.width;
				const sizerHeight = sizeDimensions.height;

				if (sizerHeight) {
					iframe.style.height = `${sizerHeight}px`;
				}

				if (sizerWidth) {
					iframe.style.width = `${sizerWidth}px`;
				}
			}
		};
		iframe.contentWindow.addEventListener('load', resize, false);
		sizer.contentWindow.addEventListener('resize', resize, false);
		resize();

		return () => {
			if (iframe.contentWindow) {
				iframe.contentWindow.removeEventListener('load', resize);
			}

			if (sizer.contentWindow) {
				sizer.contentWindow.removeEventListener('resize', resize);
			}
		};
	}, [content]);

	// eslint-disable-next-line jsx-a11y/iframe-has-title
	return <iframe className="safe-iframe" ref={ref} />;
};
