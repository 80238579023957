import { clsx } from 'clsx';
/* eslint-disable react/no-danger */
import type { FC } from 'react';
import { IconMap } from '@/components/raven/global/SocialIcon';
import { capitalizeFirstLetter } from '@/utils/capitalize-first-letter';

import styles from './styles.module.css';

interface EmbedFailProps {
	identifier: string;
	url: string;
}

const Services: Record<string, string> = {
	tiktok: 'TikTok',
	twitter: '𝕏',
};

export const EmbedFail: FC<EmbedFailProps> = ({ identifier, url }) => {
	if (!identifier || !url) {
		return null;
	}

	const Icon = IconMap[identifier];
	const service = Services[identifier] || capitalizeFirstLetter(identifier);
	const text = `View directly on <a href="${url}" target="_blank" rel="noreferrer">${service}</a>`;

	return (
		<div className={clsx(styles.embedFail, 'is-style-pairing-2')}>
			{Icon && <Icon />}
			<p className={styles.embedFailTitle}>
				Oops! We’re unable to load this content right now.
			</p>
			<p
				className={styles.embedFailParagraph}
				dangerouslySetInnerHTML={{ __html: text }}
			/>
		</div>
	);
};
