import clsx from 'clsx';
import Link from 'next/link';
import type { FC } from 'react';
import { decodeEntities } from '@/utils/post-helpers';

import styles from './styles.module.css';

type BaseLabelProps = {
	className?: string;
	filled?: boolean;
	href?: string;
	text: string;
	variant?: 'category' | 'commerce' | 'media' | 'sponsored';
};

type CommerceLabelProps = {
	filled: true;
	variant: 'commerce';
} & BaseLabelProps;

export type LabelProps = BaseLabelProps | CommerceLabelProps;

export const Label: FC<LabelProps> = ({
	className,
	filled,
	href,
	text,
	variant = 'category',
}) => {
	const cx = clsx(styles.label, className, styles[variant], {
		[styles.isFilled]: filled,
	});

	return href ? (
		<Link className={cx} href={href}>
			{decodeEntities(text)}
		</Link>
	) : (
		<span className={cx}>{decodeEntities(text)}</span>
	);
};
