import clsx from 'clsx';
import type { FC, PropsWithChildren } from 'react';
import { InternalLink } from '@/components/InternalLink';

import styles from './styles.module.css';

export interface ImageLinkWrapperProps extends PropsWithChildren {
	link?: string;
	linkClass?: string;
	linkTarget?: string;
	linkTo?: string;
	rel?: string;
}

export const ImageLinkWrapper: FC<ImageLinkWrapperProps> = ({
	children,
	link,
	linkClass,
	linkTarget,
	linkTo,
	rel,
}) => {
	const isInternalLink = linkTo === 'attachment';

	if (!link) {
		return children;
	}

	return isInternalLink ? (
		<InternalLink className={clsx(styles.imageLink, linkClass)} href={link}>
			{children}
		</InternalLink>
	) : (
		<a
			className={clsx(styles.imageLink, linkClass)}
			href={link}
			rel={rel}
			target={linkTarget}
		>
			{children}
		</a>
	);
};
