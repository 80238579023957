'use client';

import { isNonEmptyString } from 'tempest-common';
import { useAdService } from '@/context/AdServiceContext';
import { useCommerceItem } from '@/context/CommerceItemContext';
import { useCommerceConfig } from '@/context/CommerceConfigContext';
import { serializeExperiments } from '@/utils/serialize-experiments';
import {
	type FC,
	useState,
	useEffect,
	type ReactNode,
	type MouseEvent,
} from 'react';
import {
	SelectOfferActionType,
	selectSingleOfferReducer,
	type SelectSingleOfferState,
} from '@/components/Commerce/reducers';
import {
	dataToProps,
	getLinkWrapperFns,
	getLinkWrapperConfigs,
	permutiveKeyValueMapper,
	getDynamicAttributesMapper,
} from '@/components/Commerce/CommerceLink/util';
import {
	type CommerceItem,
	applyLinkWrappers,
	type CommerceOffer,
	buildExternalOffer,
	type LinkEmbedConfig,
	buildMappedValueObject,
	identifyLinkWrappersForOffer,
} from '@themaven-net/commerce-shared';

export interface CommerceLinkProps {
	children: ReactNode;
	className?: string;
	commerceConfig?: LinkEmbedConfig;
	commerceItem?: CommerceItem;
	commerceOffer?: CommerceOffer;
	disableSkimlinks: boolean;
	disableTrackonomics: boolean;
	href?: string;
	linkTarget?: string;
	phxTrackId?: string;
	rel?: string;
}

export const CommerceLink: FC<CommerceLinkProps> = ({
	children,
	className,
	commerceConfig,
	commerceItem,
	commerceOffer,
	disableSkimlinks,
	disableTrackonomics,
	href,
	linkTarget,
	phxTrackId,
	rel,
}) => {
	const { experiments, viewCorrelator } = useAdService();
	const commerceConfigContext = useCommerceConfig();
	const commerceItemContext = useCommerceItem();
	const initialItem = commerceItem ?? commerceItemContext?.commerceItem;
	const InitialOfferState: SelectSingleOfferState = {
		offer: commerceOffer ?? initialItem?.offers?.[0],
		offerMetadata: {
			preferredAffiliates:
				commerceConfigContext?.phxCommerce?.preferredAffiliates ?? [],
			preferredMerchants:
				commerceConfigContext?.phxCommerce?.preferredMerchants ?? [],
		},
	};

	// Offer effect
	const [offerState, setOfferState] =
		useState<SelectSingleOfferState>(InitialOfferState);

	useEffect(() => {
		if (offerState.offer !== undefined) {
			const newOfferState = selectSingleOfferReducer(offerState, {
				payload: { offer: offerState.offer },
				type: SelectOfferActionType.UPDATE_OFFER_SELECTION,
			});
			if (newOfferState.offer?.url !== offerState.offer?.url) {
				setOfferState(newOfferState);
			}
		}
	}, [offerState, offerState.offer]);

	// Wrap Href effect
	const [wrappedHref, setWrappedHref] = useState(offerState.offer?.url);
	useEffect(() => {
		if (offerState.offer === undefined) {
			// no manual anchor tag, no href, and no supplied item/offer, use # to be safe
			const newOfferState = selectSingleOfferReducer(offerState, {
				payload: { offer: buildExternalOffer('#') },
				type: SelectOfferActionType.SET_OFFER,
			});
			setOfferState(newOfferState);
		}
		let linkWrapperConfigs = identifyLinkWrappersForOffer(
			offerState.offer,
			commerceConfig?.options?.linkWrappersByIdentifier,
		);

		if (
			commerceConfig?.options?.linkWrappersByIdentifier &&
			(linkWrapperConfigs === undefined ||
				linkWrapperConfigs.some((config) => config?.options === undefined))
		) {
			linkWrapperConfigs = getLinkWrapperConfigs(
				commerceConfigContext,
				commerceConfig?.options?.linkWrappersByIdentifier,
				disableSkimlinks,
				disableTrackonomics,
			);
		}

		if (
			offerState.offer &&
			linkWrapperConfigs &&
			linkWrapperConfigs.length > 0
		) {
			let { url } = offerState.offer;
			if (url === undefined || url === '#') {
				url = href ?? '';
			}
			if (url) {
				setWrappedHref(
					applyLinkWrappers(
						url,
						linkWrapperConfigs,
						getLinkWrapperFns(
							viewCorrelator?.toString() ?? '0',
							serializeExperiments(experiments),
							commerceConfigContext?.affiliateTags,
						),
					),
				);
			}
		}
	}, [
		commerceConfig?.options,
		commerceConfig?.options?.linkWrappersByIdentifier,
		commerceConfigContext,
		disableSkimlinks,
		disableTrackonomics,
		experiments,
		href,
		offerState,
		offerState.offer,
		viewCorrelator,
	]);

	const handleLinkClick = (clickEvent: MouseEvent) => {
		const targetElement = clickEvent.target as HTMLElement;
		const anchor = targetElement.closest('a');
		const item = commerceItem ?? commerceItemContext?.commerceItem;
		if (!anchor) {
			return;
		}

		const permutive = commerceConfigContext.permutive || window.permutive;
		const phoenixTrackClickEvent =
			commerceConfigContext.phoenixTrackClickEvent ||
			window.phoenixTrackClickEvent;

		if (typeof phoenixTrackClickEvent === 'function') {
			phoenixTrackClickEvent(anchor, clickEvent);
		}

		if (typeof permutive?.track === 'function') {
			permutive.track(
				'AffiliateLinkClick',
				buildMappedValueObject(
					permutiveKeyValueMapper(offerState.offer?.url || '', item),
				),
			);
		}
	};

	return (
		<a
			className={className}
			href={wrappedHref || href}
			onClick={handleLinkClick}
			phx-track-id={isNonEmptyString(phxTrackId) ? phxTrackId : undefined}
			rel={rel}
			target={linkTarget}
			{...dataToProps(getDynamicAttributesMapper(offerState.offer?.url || ''))}
		>
			{children}
		</a>
	);
};
