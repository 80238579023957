import clsx from 'clsx';
import type { FC } from 'react';
import { Radio } from '@/components/raven/Inputs/Radio';
import { Label } from '@/components/raven/Inputs/Label';

import styles from './styles.module.css';
import type { PollDataOption } from './util';

export const PollOption: FC<{
	hasVoted: boolean;
	isClosed: boolean;
	onChange: () => void;
	option: PollDataOption;
	selectedOption: null | string;
}> = ({ hasVoted, isClosed, onChange, option, selectedOption }) => {
	const isOpen = !isClosed;
	const isSelectedOption = option.id === selectedOption;
	const isNotSelectedOption = selectedOption && option.id !== selectedOption;

	return (
		<div
			className={clsx(styles.poll__option, {
				[styles.poll__optionNonSelected]:
					(isClosed && !option.isWinner) || isNotSelectedOption,
				[styles.poll__optionSelected]: isOpen && isSelectedOption,
				[styles.poll__optionVoted]: isOpen && hasVoted && isSelectedOption,
				[styles.poll__winner]: option.isWinner,
			})}
			key={option.id}
		>
			{isOpen ? (
				<Radio
					checked={isSelectedOption}
					disabled={hasVoted}
					id={`input-${option.id}`}
					label={option.value}
					labelHidden={hasVoted || isClosed}
					name="poll-option"
					onChange={onChange}
					value={option.id}
				/>
			) : null}
			<Label
				as="label"
				className={styles.poll__optionLabel}
				htmlFor={`input-${option.id}`}
				labelHidden={isOpen && !hasVoted}
			>
				<div className={styles.poll__optionText}>
					<div>{option.value}</div>
					<h5 className={styles.poll__optionPercentage}>
						{option.percentage}%
					</h5>
				</div>
				<progress
					className={clsx(styles.poll__progressBar, {
						[styles.poll__progressBarVoted]:
							isOpen && hasVoted && isSelectedOption,
						[styles.poll__progressBarWinner]: option.isWinner,
					})}
					max="100"
					value={option.percentage}
				/>
			</Label>
		</div>
	);
};
