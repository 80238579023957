'use client';

import { useEffect } from 'react';
import { EmbedScriptsMap } from '@/constants';

/*
In previous versions there was a “re‑scan” (or “load”) function available that
you could call after adding an embed element dynamically. However, in the
current version of the TikTok embed code, that public API has been removed.
The script now automatically mounts embeds once when it first loads, and it does
 not expose a function (such as a load() or mount() method on a global object)
  that you can call later.
 */
export const TikTokEmbedScript = () => {
	useEffect(() => {
		const removeTikTokScript = () => {
			const existingScript = document.querySelector(
				`script[src="${EmbedScriptsMap.tiktok}"]`,
			);
			if (existingScript) {
				existingScript.remove();
			}
		};
		const addTikTokScript = () => {
			const script = document.createElement('script');
			script.src = EmbedScriptsMap.tiktok;
			script.async = true;
			document.body.appendChild(script);
		};

		if (typeof window.tiktokEmbed !== 'undefined') {
			removeTikTokScript();
			addTikTokScript();

			return () => {
				removeTikTokScript();
			};
		}

		return () => {};
	}, []);

	return null;
};
