'use client';

import type { FC, PropsWithChildren } from 'react';
import { EmbedWrapper } from '@/components/EmbedWrapper';

interface RedditEmbedWrapperProps extends PropsWithChildren {
	url: string;
}

export const RedditEmbedWrapper: FC<RedditEmbedWrapperProps> = ({
	children,
	url,
}) => (
	<EmbedWrapper identifier="reddit" url={url}>
		{children}
	</EmbedWrapper>
);
