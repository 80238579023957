'use client';

import type { FC } from 'react';
import { ExCoPlayer } from '@/components/ExCoPlayer';

type FallbackVideoProps = {
	type?: 'exCo' | 'jwPlayer';
};

export const FallbackVideo: FC<FallbackVideoProps> = ({ type = 'exCo' }) => {
	if (type === 'exCo') {
		return <ExCoPlayer />;
	}
	if (type === 'jwPlayer') {
		// TBD: render JWPlayer video
	}
	return null;
};
