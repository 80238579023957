import clsx from 'clsx';
import type { FC, ComponentPropsWithRef } from 'react';
import ArrowLeft from '@themaven-net/raven-assets/ArrowLeft';
import ArrowRight from '@themaven-net/raven-assets/ArrowRight';

type PropType = {
	active?: boolean;
} & ComponentPropsWithRef<'button'>;

export const PrevButton: FC<PropType> = (props) => {
	const { children, className, ...restProps } = props;
	const cx = clsx('button button--icon-large', className);
	return (
		<button className={cx} type="button" {...restProps}>
			<ArrowLeft />
			{children}
		</button>
	);
};

export const NextButton: FC<PropType> = (props) => {
	const { children, className, ...restProps } = props;
	const cx = clsx('button button--icon-large', className);
	return (
		<button className={cx} type="button" {...restProps}>
			<ArrowRight />
			{children}
		</button>
	);
};

export const DotButton: FC<PropType> = (props) => {
	const { active, children, ...restProps } = props;
	const cx = clsx('carousel__dot', { 'carousel__dot--active': active });
	return (
		<button className={cx} type="button" {...restProps}>
			{children}
		</button>
	);
};
