type TimerStates = 'Init' | 'Running' | 'Stopped';

/**
 * Time keeping class, allows for start/stop/reset.
 *
 * Optionally, a callback method and callback time (in ms)
 * can be supplied, which will be called with the current time.
 */
export class Timer {
	private state: TimerStates = 'Init';

	private elapsed: number = 0;

	private startTime: number | undefined;

	private intervalToken: ReturnType<typeof setInterval> | undefined;

	private callback?: (() => void) | ((elapsed: number) => void) | null;

	private callbackTick?: number;

	/**
	 * return an MM:SS or HH:MM:SS formated string from the timer depending on elapsed time
	 *
	 * @see https://sabe.io/blog/javascript-convert-milliseconds-seconds-minutes-hours
	 */
	public get formattedElapsed(): string {
		if (this.elapsed === 0) {
			return '00:00';
		}

		const seconds = Math.floor((this.elapsed / 1000) % 60);
		const minutes = Math.floor((this.elapsed / 1000 / 60) % 60);
		const hours = Math.floor((this.elapsed / 1000 / 60 / 60) % 24);

		const output = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

		if (hours === 0) {
			return output;
		}

		return `${hours.toString().padStart(2, '0')}:${output}`;
	}

	public constructor(
		intervalCallback?: (() => void) | ((elapsed: number) => void) | null,
		refreshInterval: number = 1000,
	) {
		if (intervalCallback) {
			this.callback = intervalCallback;
			this.callbackTick = refreshInterval;
		}

		this.tick = this.tick.bind(this);
	}

	public reset(): void {
		this.startTime = undefined;
		this.state = 'Init';
		this.elapsed = 0;

		if (this.intervalToken) {
			clearInterval(this.intervalToken);
			this.intervalToken = undefined;
		}
	}

	public start(): void {
		if (this.state === 'Running') {
			return;
		}

		this.startTime = Date.now();
		this.state = 'Running';

		if (this.callback) {
			this.intervalToken = setInterval(this.tick, this.callbackTick);
		}
	}

	public stop(): void {
		if (this.state !== 'Running') {
			return;
		}

		this.updateElapsed();
		this.state = 'Stopped';

		if (this.intervalToken) {
			clearInterval(this.intervalToken);
			this.intervalToken = undefined;
		}
	}

	protected tick(): void {
		this.updateElapsed();

		if (this.callback) {
			this.callback(this.elapsed);
		}
	}

	protected updateElapsed(): void {
		if (!this.startTime) {
			return;
		}

		const current = Date.now();
		this.elapsed += current - this.startTime;
		this.startTime = current;
	}
}
