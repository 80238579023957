'use client';

/**
 * ExCoPlayer integration. API documentation: https://developer.ex.co/#/introduction/getting-started
 */

import { GDPR } from '@/components/GDPR';
import { useRef, useEffect } from 'react';
import { cleanPath } from '@/utils/clean-path';
import { loadScript } from '@/utils/load-script';
import { useArticle } from '@/context/ArticleContext';
import { getPageOfDay } from '@/utils/get-page-of-day';
import { useSiteContext } from '@/context/SiteContext';
import { useAdService } from '@/context/AdServiceContext';
import { useGaContext } from '@/context/GoogleAnalyticsContext';
import { serializeExperiments } from '@/utils/serialize-experiments';

import type { IExCoPlayer, ExCoMessageListener } from './types';
import './styles.css';

const EXCOPLAYER_SCRIPT = 'https://player.ex.co/player/';

const createGpid = (
	path: string,
	zone: string,
	index: number,
	gamNetworkCode: string,
) => {
	const firstLevelPath = path === '/' ? path : `/${path.split('/')[1]}/`;
	return `/${gamNetworkCode}${firstLevelPath}video/${zone}-${index}`;
};

// Loading the ExCo script does not guarantee the availability of the ExCoPlayer
// object. We have to poll and wait for it to initialize.
const getExCoPlayer = (playerId: string) => {
	return new Promise<IExCoPlayer>((resolve) => {
		const interval = setInterval(() => {
			const ep = window.ExCoPlayer;
			if (ep) {
				clearInterval(interval);
				resolve(ep.connect(playerId));
			}
		}, 100);
	});
};

const ExCoPlayerComponent = () => {
	const { sendGaEvent } = useGaContext();
	const playerDiv = useRef<HTMLDivElement | null>(null);
	const playlistIndex = useRef(0);
	const article = useArticle();
	const { config, gaVars } = useSiteContext();
	const adService = useAdService();
	const { channelKey } = config;
	const {
		video: {
			exCo: { id: playerId, isEnabled },
		},
	} = config;

	useEffect(() => {
		const path = article ? cleanPath(article.link) : '/';
		const author =
			(article?.meta?.author_profile_id ?? []).length > 0
				? `tm-${article!.meta!.author_profile_id![0]}`
				: undefined;
		const terms = (article?.tags ?? [])
			.filter((tag) => tag.taxonomy === 'post_tag')
			.map((tag) => tag.name);
		const experimentString = serializeExperiments(adService.experiments);

		const exsConfig = {
			customParams: {
				author,
				channel: channelKey,
				correlator: adService.viewCorrelator,
				direct: article?.meta?.monetization === 'direct_only' ? '1' : '0',
				pagetype: article?.meta?.page_type,
				path,
				pod: getPageOfDay(false),
				terms: terms.join(','),
			},
			macros: {
				gpid: createGpid(path, 'preroll', 0, config.ad.gamNetworkCode),
			},
			reporting: {
				subId: channelKey,
				subId2: author || undefined,
				utmContent: experimentString || undefined,
			},
			showAds:
				adService.showAds && article?.meta?.monetization === 'monetizable',
		};
		const updateDiv = () => {
			playerDiv.current?.setAttribute(
				'data-exs-config',
				JSON.stringify(exsConfig),
			);
		};
		updateDiv();

		const handleMessage: ExCoMessageListener = ({ data = {} }) => {
			const { metadata, name, type } = data;
			if (type === 'exco-event' && name === 'playlist-index-changed') {
				playlistIndex.current += 1;
				exsConfig.macros.gpid = createGpid(
					path,
					'preroll',
					playlistIndex.current,
					config.ad.gamNetworkCode,
				);
				updateDiv();
				window.ExCoPlayer.connect(playerId).init(exsConfig);
			}
			if (type === 'exco-event' && name === 'player-playing') {
				sendGaEvent('video_play_exco', {
					sendTo: gaVars.platformTracker,
					videoId: metadata!.playerId,
				});
			}
		};

		// EX.CO's video player sends all player events as message events.
		window.addEventListener('message', handleMessage);

		loadScript(
			`${EXCOPLAYER_SCRIPT}${playerId}`,
			{ className: 'exco-player', id: 'exco-player', programmatic: 'true' },
			true,
		);
		getExCoPlayer(playerId).then((player: IExCoPlayer) => {
			player?.init(exsConfig);
		});

		return () => {
			window.ExCoPlayer?.connect(playerId)?.destroy();
			window.removeEventListener('message', handleMessage);
		};
	}, [
		adService,
		article,
		channelKey,
		playerId,
		sendGaEvent,
		config.ad.gamNetworkCode,
		gaVars.platformTracker,
	]);

	if (!isEnabled) {
		return null;
	}

	return (
		<div className="exco-player-wrapper">
			<div id={playerId} ref={playerDiv} />
		</div>
	);
};

export const ExCoPlayer = () => {
	return (
		<GDPR
			purposes={[
				'content/personalized',
				'advertising/basic',
				'advertising/personalized',
				'storage',
			]}
			vendorId="excoplayer"
		>
			<ExCoPlayerComponent />
		</GDPR>
	);
};
