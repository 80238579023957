'use client';

import type { FC, PropsWithChildren } from 'react';
import { EmbedWrapper } from '@/components/EmbedWrapper';

const onLoad = () => {
	window.FB?.XFBML?.parse();
};

interface FacebookEmbedWrapperProps extends PropsWithChildren {
	url: string;
}

export const FacebookEmbedWrapper: FC<FacebookEmbedWrapperProps> = ({
	children,
	url,
}) => (
	<EmbedWrapper
		identifier="facebook"
		onAlreadyLoaded={onLoad}
		onLoad={onLoad}
		url={url}
	>
		{children}
	</EmbedWrapper>
);
