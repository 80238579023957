import type { FC, CSSProperties } from 'react';
import { NumbrixCell } from '@/components/raven/Numbrix/NumbrixCell';
import { NumbrixBoardMsg } from '@/components/raven/Numbrix/NumbrixBoardMsg';
import { useNumbrixGame } from '@/components/raven/Numbrix/NumbrixGameContext';

import styles from './styles.module.css';

export const NumbrixBoard: FC = () => {
	const {
		cellsRef,
		handleCellBlur,
		handleCellFocus,
		handleCellMove,
		handleCellSolved,
		state: { cells, gameState },
	} = useNumbrixGame();
	const hidden = gameState !== 'Active' && gameState !== 'Solving';
	const cellStyles = {
		'--m-numbrix-number-of-cells': Math.sqrt(cells.length),
	} as CSSProperties;

	return (
		<div className={styles.numbrixBoard}>
			<div
				className={styles.numbrixGameBoard}
				hidden={hidden}
				style={cellStyles}
			>
				{cells.map((cell) => (
					<NumbrixCell
						gameActive={gameState === 'Active'}
						key={cell.cellIndex}
						maxCells={cells.length}
						onCellBlur={handleCellBlur}
						onCellFocus={handleCellFocus}
						onCellSolved={handleCellSolved}
						onMove={handleCellMove}
						options={cell}
						ref={(ref) => {
							cellsRef.current[cell.cellIndex] = ref;
						}}
					/>
				))}
			</div>

			<NumbrixBoardMsg />
		</div>
	);
};
