import type { EmblaCarouselType } from 'embla-carousel';
import { useState, useEffect, useCallback } from 'react';

type UseCarouselControlsType = {
	nextBtnDisabled: boolean;
	onDotButtonClick: (index: number) => void;
	onNextButtonClick: () => void;
	onPrevButtonClick: () => void;
	prevBtnDisabled: boolean;
	scrollSnaps: number[];
	selectedIndex: number;
	totalPages: number;
};

export const useCarouselControls = (
	carouselApi: EmblaCarouselType | undefined,
): UseCarouselControlsType => {
	const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
	const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
	const [selectedIndex, setSelectedIndex] = useState(1);
	const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
	const [totalPages, setTotalPages] = useState(0);

	const onPrevButtonClick = useCallback(() => {
		if (!carouselApi) {
			return;
		}
		carouselApi.scrollPrev();
	}, [carouselApi]);

	const onNextButtonClick = useCallback(() => {
		if (!carouselApi) {
			return;
		}
		carouselApi.scrollNext();
	}, [carouselApi]);

	const onDotButtonClick = useCallback(
		(index: number) => {
			if (!carouselApi) {
				return;
			}
			carouselApi.scrollTo(index);
		},
		[carouselApi],
	);

	const onSelect = useCallback((cApi: EmblaCarouselType) => {
		setPrevBtnDisabled(!cApi.canScrollPrev());
		setNextBtnDisabled(!cApi.canScrollNext());
		setSelectedIndex(cApi.selectedScrollSnap());
		const pages = cApi.scrollSnapList();
		setScrollSnaps(pages);
		setTotalPages(pages.length);
	}, []);

	useEffect(() => {
		if (!carouselApi) {
			return;
		}

		onSelect(carouselApi);
		carouselApi.on('reInit', onSelect).on('select', onSelect);
	}, [carouselApi, onSelect]);

	return {
		nextBtnDisabled,
		onDotButtonClick,
		onNextButtonClick,
		onPrevButtonClick,
		prevBtnDisabled,
		scrollSnaps,
		selectedIndex,
		totalPages,
	};
};
