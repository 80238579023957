import clsx from 'clsx';
import { useRef, type FC } from 'react';

import styles from './styles.module.css';

interface RadioProps {
	checked?: boolean;
	className?: string;
	disabled?: boolean;
	id: string;
	label: string;
	labelHidden?: boolean;
	name?: string;
	onChange?: () => void;
	value?: string;
}

export const Radio: FC<RadioProps> = ({
	checked,
	className,
	disabled,
	id,
	label,
	labelHidden,
	name,
	onChange,
	value,
}) => {
	const radioRef = useRef<HTMLInputElement>(null);

	if (!id || !label) {
		return null;
	}

	return (
		<div className={clsx(styles.radio, className)}>
			<label className={styles.radioWrapper} htmlFor={id}>
				<input
					checked={checked}
					className={styles.radioInput}
					disabled={disabled}
					id={id}
					name={name}
					onChange={onChange}
					ref={radioRef}
					type="radio"
					value={value}
				/>
				<span className={styles.radioControl} />
				<span
					className={clsx(styles.radioLabel, {
						'visually-hidden': labelHidden,
					})}
				>
					{label}
				</span>
			</label>
		</div>
	);
};
