'use client';

import type { Post } from '@/types/entities';
import type { AffiliateTag } from 'tempest-common';
import { useSiteContext } from '@/context/SiteContext';
import type { AppSettings } from '@/utils/data/fetch-app-settings';
import { useFetchAppSettings } from '@/utils/hooks/use-fetch-app-settings';
import {
	type FC,
	useMemo,
	useContext,
	createContext,
	type ReactNode,
} from 'react';
import type {
	SkimlinksWrapperConfig,
	TrackonomicsWrapperConfig,
} from '@themaven-net/commerce-shared';

type Permutive = {
	track: (event: string, mapper: unknown) => void;
};

type PhoenixTrackClickEvent = (
	anchor: HTMLAnchorElement,
	clickEvent: unknown,
) => object;

type PhoenixCommerce = {
	amazonNca?: { asins: { [key: string]: Record<string, string> } };
	preferredAffiliates?: string[];
	preferredMerchants?: string[];
};

export enum AffiliateTagLevel {
	/** Affiliate tag provided by unified config (site level) */
	Site,
	/** Affiliate tag set at post level */
	Post,
}

export type AffiliateTagWithLevel = {
	level: AffiliateTagLevel;
	value: AffiliateTag;
};

export interface CommerceConfigContextValues {
	affiliateTags?: AffiliateTagWithLevel[];
	permutive?: Permutive;
	phoenixTrackClickEvent?: PhoenixTrackClickEvent;
	phxCommerce?: PhoenixCommerce;
	skimlinks: SkimlinksWrapperConfig;
	trackonomics: TrackonomicsWrapperConfig;
	wrappersEnabled?: boolean;
}

export const CommerceConfigContext = createContext<
	CommerceConfigContextValues | undefined
>(undefined);

export const useCommerceConfig = () => {
	const context = useContext(CommerceConfigContext);

	if (!context) {
		throw new Error(
			'useCommerceConfig must be used within a CommerceConfigContextProvider',
		);
	}

	return context;
};

export const CommerceConfigContextProvider: FC<{
	children: ReactNode;
	permutive?: Permutive;
	phoenixTrackClickEvent?: PhoenixTrackClickEvent;
	phxCommerce?: PhoenixCommerce;
	postData?: Post;
}> = ({
	children,
	permutive,
	phoenixTrackClickEvent,
	phxCommerce,
	postData,
}) => {
	const appSettings = useFetchAppSettings();
	const siteSettings = appSettings?.settings as AppSettings['settings'];
	const { config } = useSiteContext();
	const { affiliateVendors, channelKey, siteId } = config;
	const wrappersEnabled =
		siteSettings?.analytics?.trackonomics?.link_wrapping &&
		!postData?.meta?.trackonomics_disabled;
	const { id: skimlinksPublisherId } = affiliateVendors.providers.Skimlinks;
	const trackonomicsSiteId =
		siteSettings?.analytics?.trackonomics?.site_id || '';
	return (
		<CommerceConfigContext.Provider
			value={useMemo(() => {
				// We currently only have site level tags
				const affiliateTags: AffiliateTagWithLevel[] = [
					{
						level: AffiliateTagLevel.Site,
						value: {
							affiliate: 'Amazon',
							tag:
								affiliateVendors.providers.AmazonAffiliate.defaultAffiliateId ??
								'',
						},
					},
				];
				const skimlinks: SkimlinksWrapperConfig = {
					params: {
						publisherId: skimlinksPublisherId ?? '',
					},
					vendor: 'Skimlinks',
				};
				const trackonomics: TrackonomicsWrapperConfig = {
					options: {
						author: postData?.authors[0]?.name ?? '',
						item_id: postData?.meta?.tempest_id ?? '',
						mc: channelKey,
						page_type: 'article', // TODO: We want to handle non post pages
						pageUrl: postData?.link ?? '',
						section: postData?.primaryCategory?.name ?? '',
						site_id: siteId,
						siteCode: trackonomicsSiteId,
					},
					vendor: 'Trackonomics',
				};
				return {
					affiliateTags,
					permutive,
					phoenixTrackClickEvent,
					phxCommerce,
					skimlinks,
					trackonomics,
					wrappersEnabled,
				};
			}, [
				affiliateVendors,
				channelKey,
				permutive,
				phoenixTrackClickEvent,
				phxCommerce,
				postData?.authors,
				postData?.link,
				postData?.meta?.tempest_id,
				postData?.primaryCategory?.name,
				siteId,
				skimlinksPublisherId,
				trackonomicsSiteId,
				wrappersEnabled,
			])}
		>
			{children}
		</CommerceConfigContext.Provider>
	);
};
