'use client';

import type { FC } from 'react';

import { Newsletter } from '../../Newsletter';

/**
 * NewsletterBlock React component.
 * This is a server-side component which returns markup for the client-side
 * Newsletter component.
 * Expected attributes on the provided `domNode`:
 * - data-smart-slot-config: The configuration for the ad slot (identifies breakpoint, zone, and index).
 */
export const NewsletterBlockWrapper: FC = () => {
	return <Newsletter isInContent placement="inline" />;
};
