import type { SiteConfig } from '@/temporary-site-config';

/**
 * The function generates a JW Player script URL based on the provided SiteConfig.
 * @param {SiteConfig} config - The `config` parameter is an object that contains site configuration
 * settings, including video settings for JWPlayer. It is expected to have a `video` property which in
 * turn has a `jwplayer` property containing the `playerId` for JWPlayer.
 * @returns `https://cdn.jwplayer.com/libraries/playerIdValue.js`
 */
export const generateJWPlayerScript = (config: SiteConfig) => {
	const playerId = config.video?.jwplayer?.playerId;

	if (!playerId) {
		return null;
	}

	return `https://cdn.jwplayer.com/libraries/${playerId}.js`;
};
