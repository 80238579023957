'use client';

import clsx from 'clsx';
import type { CtaProps } from '@/types/cta';
import { Image } from '@/components/raven/Image';
import useEmblaCarousel from 'embla-carousel-react';
import Close from '@themaven-net/raven-assets/Close';
import Expand from '@themaven-net/raven-assets/Expand';
import { useLockScroll } from '@/utils/hooks/use-lock-scroll';
import { ImageLinkWrapper } from '@/components/ImageLinkWrapper';
import { renderCommerceLink } from '@/utils/commerce/commerceUtils';
import { useCarouselControls } from '@/utils/hooks/use-carousel-controls';
import { useDebouncedCallback } from '@/utils/hooks/use-debounced-callback';
import { extractCaptions, type SwipeGalleryImage } from '@/utils/image-utils';
import {
	PrevButton,
	NextButton,
} from '@/components/raven/content/Carousels/Controls';
import {
	isCommerceOffer,
	isCompleteCommerceItem,
} from '@themaven-net/commerce-shared';
import {
	useRef,
	type FC,
	useState,
	useEffect,
	useCallback,
	type ReactNode,
	type CSSProperties,
} from 'react';

import styles from './styles.module.css';

const getFocusableElements = (element: HTMLElement | null) => {
	if (!element) {
		return [];
	}

	return element.querySelectorAll(
		'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
	);
};

interface SwipeGalleryProps {
	children?: ReactNode;
	ctaClass?: string;
	ctaTitle?: CtaProps['title'];
	images: SwipeGalleryImage[];
}

export const SwipeGallery: FC<SwipeGalleryProps> = ({
	children,
	ctaClass,
	ctaTitle,
	images,
}) => {
	const swipeGalleryRef = useRef<HTMLDivElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);
	const [isGalleryOpen, setIsGalleryOpen] = useState(false);
	const [zoomLevel, setZoomLevel] = useState(1);

	const [carouselRef, carouselApi] = useEmblaCarousel({
		align: 'start',
		loop: true,
		slidesToScroll: 1,
	});

	const {
		nextBtnDisabled,
		onNextButtonClick,
		onPrevButtonClick,
		prevBtnDisabled,
		selectedIndex,
		totalPages,
	} = useCarouselControls(carouselApi);

	const handleOpenGallery = () => {
		const focusableElements = getFocusableElements(swipeGalleryRef.current);

		/* Focus the first focusable element after element visible transition */
		setTimeout(() => {
			(focusableElements[0] as HTMLElement).focus();
		}, 200);

		setIsGalleryOpen(true);
	};

	useLockScroll(isGalleryOpen);

	const handleCloseGallery = useCallback(() => {
		if (isGalleryOpen) {
			buttonRef.current?.focus();
			setIsGalleryOpen(false);
		}
	}, [isGalleryOpen]);

	const gotoPreviousImage = useCallback(() => {
		onPrevButtonClick();
	}, [onPrevButtonClick]);

	const gotoNextImage = useCallback(() => {
		onNextButtonClick();
	}, [onNextButtonClick]);

	const handleZoomIn = useCallback(() => {
		if (carouselApi) {
			carouselApi.reInit({ watchDrag: false });
			setZoomLevel(2);
		}
	}, [carouselApi]);

	const handleZoomOut = () => {
		if (carouselApi) {
			carouselApi.reInit({ watchDrag: true });
			setZoomLevel(1);
		}
	};

	const handleCloseButton = () => {
		if (zoomLevel === 1) {
			handleCloseGallery();
		} else {
			handleZoomOut();
		}
	};

	const handleClickOutside = (e: MouseEvent) => {
		if (swipeGalleryRef.current) {
			if (!swipeGalleryRef.current.contains(e.target as Node)) {
				handleCloseGallery();
			}
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => document.removeEventListener('mousedown', handleClickOutside);
	});

	const handleArrowKeyPress = useCallback(
		(event: KeyboardEvent) => {
			switch (event.key) {
				case 'ArrowLeft':
					event.preventDefault();
					gotoPreviousImage();
					break;
				case 'ArrowRight':
					event.preventDefault();
					gotoNextImage();
					break;
				default:
					break;
			}
		},
		[gotoNextImage, gotoPreviousImage],
	);

	const debouncedHandleArrowKeyPress = useDebouncedCallback(
		(event: unknown) => handleArrowKeyPress(event as KeyboardEvent),
		[],
		300,
	);
	const imageSrcToCaptionReactNodes = extractCaptions(children);
	useEffect(() => {
		if (isGalleryOpen && swipeGalleryRef.current) {
			const focusableElements = getFocusableElements(swipeGalleryRef.current);

			if (focusableElements.length > 0) {
				const firstElement = focusableElements[0];
				const lastElement = focusableElements[focusableElements.length - 1];

				const handleKeyPress = (event: KeyboardEvent) => {
					switch (event.key) {
						case 'Escape':
							handleCloseGallery();
							break;
						case 'Tab':
							if (event.shiftKey && document.activeElement === firstElement) {
								event.preventDefault();
								(lastElement as HTMLElement).focus();
							} else if (
								!event.shiftKey &&
								document.activeElement === lastElement
							) {
								event.preventDefault();
								(firstElement as HTMLElement).focus();
							}
							break;
						default:
							break;
					}
				};

				document.addEventListener('keydown', handleKeyPress);
				document.addEventListener('keydown', debouncedHandleArrowKeyPress);

				return () => {
					document.removeEventListener('keydown', handleKeyPress);
					document.removeEventListener('keydown', debouncedHandleArrowKeyPress);
				};
			}
		}

		return () => {};
	}, [
		isGalleryOpen,
		handleArrowKeyPress,
		debouncedHandleArrowKeyPress,
		handleCloseGallery,
	]);

	return (
		<div className={styles.swipeGallery}>
			<div
				aria-hidden={!isGalleryOpen}
				aria-label="Gallery"
				aria-modal="true"
				className={styles.swipeGalleryModal}
				id="dialog1"
				role="dialog"
			>
				<div
					className={styles.swipeGalleryContent}
					ref={swipeGalleryRef}
					role="document"
				>
					<button
						aria-label="Close Gallery"
						className={styles.swipeGalleryClose}
						onClick={handleCloseButton}
						type="button"
					>
						<Close />
					</button>
					<div className={clsx('carousel', styles.swipeGalleryCarousel)}>
						<div
							className={clsx(
								'carousel__viewport',
								styles.swipeGalleryCarouselViewport,
							)}
							ref={carouselRef}
						>
							<div
								className={clsx(
									styles.swipeGalleryImages,
									'carousel__container',
									{
										[styles.swipeGalleryImagesZoomed]: zoomLevel > 1,
									},
								)}
							>
								{images.map((image) => {
									const imageStyle: CSSProperties = {
										transform: `scale(${zoomLevel})`,
									};
									const caption = imageSrcToCaptionReactNodes[image.source_url];
									if (
										zoomLevel === 1 &&
										image.meta?.focal_point?.x &&
										image.meta?.focal_point?.y
									) {
										imageStyle.objectPosition = `${image.meta.focal_point.x.toFixed(2)}% ${image.meta.focal_point.y.toFixed(2)}%`;
									}

									return (
										<div
											className={styles.swipeGalleryItemWrapper}
											key={image.id}
										>
											<figure
												className={clsx(styles.swipeGalleryItem, {
													[styles.swipeGalleryItemZoomed]: zoomLevel > 1,
												})}
											>
												{image.hasCommerceLink ? (
													renderCommerceLink(
														<Image
															alt={image.alt_text ?? ''}
															className={styles.swipeGalleryImage}
															fill
															sizes="100vw"
															src={image.source_url}
															style={imageStyle}
														/>,
														isCommerceOffer(
															image?.commerceConfig?.item?.offers?.[0],
														)
															? image?.commerceConfig?.item?.offers?.[0]
															: '',
														isCompleteCommerceItem(image?.commerceConfig?.item)
															? image?.commerceConfig?.item
															: undefined,
														styles.swipeGalleryImageLink,
														'image-link',
														image?.commerceConfig,
													)
												) : (
													<ImageLinkWrapper
														link={image.link}
														linkClass={styles.swipeGalleryImageLink}
														linkTarget={image.linkTarget}
														linkTo={image.linkTo}
														rel={image.rel}
													>
														<Image
															alt={image.alt_text ?? ''}
															className={styles.swipeGalleryImage}
															fill
															sizes="100vw"
															src={image.source_url}
															style={imageStyle}
														/>
													</ImageLinkWrapper>
												)}
											</figure>
											{zoomLevel === 1 && (
												<div className={styles.swipeGallerySlideContent}>
													{caption && (
														<h2 className={styles.swipeGalleryCaption}>
															{caption}
														</h2>
													)}
												</div>
											)}
										</div>
									);
								})}
							</div>
						</div>
					</div>
					{zoomLevel === 1 && (
						<div className={styles.swipeGalleryCounter}>
							{selectedIndex + 1} / {totalPages}
							<button
								aria-label="Zoom In"
								className={clsx(
									styles.swipeGalleryZoomButton,
									'button button--outline is-dark',
								)}
								onClick={handleZoomIn}
								type="button"
							>
								Zoom In <Expand />
							</button>
						</div>
					)}

					<div className={styles.slideGalleryNav}>
						<PrevButton
							aria-label="Previous Image"
							className={styles.swipeGalleryNextButton}
							disabled={prevBtnDisabled}
							onClick={onPrevButtonClick}
						/>
						<NextButton
							aria-label="Next Image"
							className={styles.swipeGalleryPrevButton}
							disabled={nextBtnDisabled}
							onClick={onNextButtonClick}
						/>
					</div>
				</div>
			</div>

			<button
				className={clsx('button', ctaClass)}
				onClick={handleOpenGallery}
				ref={buttonRef}
				type="button"
			>
				{ctaTitle || 'Launch Gallery'}
			</button>
		</div>
	);
};
