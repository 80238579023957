'use client';

import clsx from 'clsx';
import {
	useRef,
	useState,
	useEffect,
	type ReactNode,
	type ElementType,
	type ComponentPropsWithoutRef,
} from 'react';

type ScrollVisibleProps<T extends ElementType> = {
	as?: T;
	children: ReactNode;
	className?: string;
	rootMargin?: string;
	style?: Record<string, unknown>;
	tallThreshold?: number;
	threshold?: number;
	visibleClass?: string;
} & Omit<
	ComponentPropsWithoutRef<T>,
	'as' | 'children' | 'className' | 'style'
>;

export const ScrollVisible = <T extends ElementType = 'div'>({
	as,
	children,
	className,
	rootMargin,
	tallThreshold = 0.25,
	threshold = 0.5,
	visibleClass = 'visible',
	...restProps
}: ScrollVisibleProps<T>) => {
	const [isVisible, setIsVisible] = useState(false);
	const ref = useRef(null);
	const TagName = as || 'div';

	useEffect(() => {
		const el = ref.current as HTMLElement | null;

		const element = el?.getBoundingClientRect();

		const dynamicThreshold =
			element && element.height > 500 ? tallThreshold : threshold;

		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsVisible(true);
					observer.unobserve(entry.target);
					observer.disconnect();
				}
			},
			{ rootMargin, threshold: dynamicThreshold },
		);

		if (el) {
			observer.observe(el);
		}

		return () => {
			if (el) {
				observer.unobserve(el);
			}
		};
	}, [rootMargin, tallThreshold, threshold]);

	const props: Record<string, unknown> = {
		className: clsx(className, {
			[visibleClass]: isVisible,
		}),
		ref,
		...restProps,
	};

	return <TagName {...props}>{children}</TagName>;
};
