'use client';

import dynamic from 'next/dynamic';
import { useMemo, useState } from 'react';
import type { Post } from '@/types/entities';
import { usePathname } from 'next/navigation';
import { useSiteContext } from '@/context/SiteContext';
import { useBreakpoint } from '@/utils/hooks/use-breakpoint';
import { SmartSlots, typeGuards } from '@themaven-net/smart-slots';
import type { CheerioElement, SmartSlotsDeps } from '@themaven-net/smart-slots';

const NewsletterComponent = dynamic(
	() => import('../Newsletter').then(({ Newsletter }) => Newsletter),
	{
		ssr: false,
	},
);

type StandaloneSmartSlotProps = {
	item: Partial<Post>;
	variant: string;
};

export const SmartSlot = ({ item, variant }: StandaloneSmartSlotProps) => {
	const { config } = useSiteContext();
	const pathname = usePathname();
	const breakpoint = useBreakpoint();
	const [newsletterSubscribed, setNewsletterSubscribed] = useState(false);

	const { smartSlotConfig } = config;
	const { ruleSet, slotType } = smartSlotConfig.widgets?.[variant] ?? {};

	const smartSlots = useMemo(() => {
		if (slotType !== 'standalone') {
			return null;
		}
		return new SmartSlots(
			{
				contentItem: item as unknown as SmartSlotsDeps['contentItem'],
				contentSizes: {
					A: 'short',
					B: 'short',
					C: 'short',
					D: 'short',
				},
				enabled: true,
				markup: '',
				path: pathname,
			},
			{
				...smartSlotConfig,
				ruleSets: [ruleSet],
				widgets: {
					[variant]: smartSlotConfig.widgets[variant],
				},
			},
		);
	}, [item, pathname, ruleSet, slotType, smartSlotConfig, variant]);

	const slot = useMemo(() => {
		if (breakpoint && smartSlots) {
			const fakeInjectionPoint = '<div></div>' as unknown as CheerioElement;
			return smartSlots.addSmartSlot(breakpoint, fakeInjectionPoint, null)
				.widget;
		}
		return null;
	}, [breakpoint, smartSlots]);

	// 1. We will render the configured fallback widget
	//    instead of hiding this in the future.
	//    WOR-2554
	switch (slot?.widgetType) {
		case 'Newsletters':
			return (
				typeGuards.isNewslettersWidget(slot) &&
				!newsletterSubscribed && ( // 1.
					<NewsletterComponent
						newsletterIds={slot.config.newsletterIds}
						onAllSubscribed={() => setNewsletterSubscribed(true)}
						placement="slot"
					/>
				)
			);
		default:
			return null;
	}
};
