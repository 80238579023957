'use client';

import type { FC } from 'react';
import { Play } from '@themaven-net/raven-assets/index';
import {
	useNumbrixGame,
	formatElapsedTime,
} from '@/components/raven/Numbrix/NumbrixGameContext';

import styles from './styles.module.css';

/**
 * Dialog displayed when the player has resigned.
 */
export const Resigned: FC = () => {
	const {
		requestRestart,
		state: { elapsedTime },
	} = useNumbrixGame();

	return (
		<>
			<p className={styles.numbrixBoardMsgTitle}>Player Resigned</p>
			<p className={styles.numbrixBoardMsgText}>
				Elapsed Time: {formatElapsedTime(elapsedTime)}
			</p>
			<span className={styles.numbrixBoardMsgNote}>
				Click on the button below to try puzzle again
			</span>
			<button
				className={styles.numbrixButtonWhite}
				onClick={requestRestart}
				type="button"
			>
				Try again <Play />
			</button>
		</>
	);
};

/**
 * Dialog displayed when the player has successfully completed the puzzle.
 */
export const Complete: FC = () => {
	const {
		requestRestart,
		state: { elapsedTime },
	} = useNumbrixGame();

	return (
		<>
			<p className={styles.numbrixBoardMsgTitle}>Puzzle Complete!</p>
			<p className={styles.numbrixBoardMsgText}>
				Your solve time was: {formatElapsedTime(elapsedTime)}
			</p>
			<span className={styles.numbrixBoardMsgNote}>
				Click on the button below to try puzzle again
			</span>
			<button
				className={styles.numbrixButtonWhite}
				onClick={requestRestart}
				type="button"
			>
				Try again <Play />
			</button>
		</>
	);
};

/**
 * Dialog displayed when the game is paused.
 */
export const Paused: FC = () => {
	const { requestResume } = useNumbrixGame();

	return (
		<>
			<p className={styles.numbrixBoardMsgTitle}>Puzzle Paused</p>
			<span className={styles.numbrixBoardMsgNote}>
				Click the button below to resume
			</span>
			<button
				className={styles.numbrixButtonWhite}
				onClick={requestResume}
				type="button"
			>
				Resume <Play />
			</button>
		</>
	);
};

/**
 * Dialog displayed when the game first loads, before game start.
 */
export const Waiting: FC = () => {
	const {
		requestStart,
		state: {
			config: { date = '', level = '' },
		},
	} = useNumbrixGame();

	return (
		<>
			{date && <p className={styles.numbrixBoardMsgTitle}>{date}</p>}
			{level && <span className={styles.numbrixBoardMsgNote}>{level}</span>}
			<button
				className={styles.numbrixButtonWhite}
				onClick={requestStart}
				type="button"
			>
				Start <Play />
			</button>
		</>
	);
};

/**
 * Don't display a dialog while the puzzle solution is being displayed.
 */
export const Solving: FC = () => null;

/**
 * Don't display a dialog while the game is active.
 */
export const Active: FC = () => null;
export const Resetting: FC = () => null;
export const ShowingDialog: FC = () => null;
