import type { CtaProps } from '@/types/cta';
import type { ButtonProps } from '@/components/raven/ui/Button';

// convert CTA props to Button props
export const ctaToButton = (cta: CtaProps): ButtonProps => {
	return {
		href: cta.url,
		label: cta.title,
		target: cta.opensInNewTab ? '_blank' : undefined,
	};
};
