'use client';

import clsx from 'clsx';
import type { CtaProps } from '@/types/cta';
import type { Post } from '@/types/entities';
import type { FC, PropsWithChildren } from 'react';
import { ctaToButton } from '@/utils/cta-to-button';
import useEmblaCarousel from 'embla-carousel-react';
import { Button } from '@/components/raven/ui/Button';
import ArrowLeft from '@themaven-net/raven-assets/ArrowLeft';
import type { SwipeGalleryImage } from '@/utils/image-utils';
import ArrowRight from '@themaven-net/raven-assets/ArrowRight';
import { SwipeGallery } from '@/components/raven/SwipeGallery';
import { useCarouselControls } from '@/utils/hooks/use-carousel-controls';
import type { CommerceInfoblockPropsWithId } from '@/components/raven/Blocks/commerce/CommerceInfoblockBlock';

import styles from './styles.module.css';

export interface CarouselProps extends PropsWithChildren {
	className?: string;
	ctaLink?: CtaProps;
	slides: CommerceInfoblockPropsWithId[] | Post[] | SwipeGalleryImage[];
	slidesToScroll?: number;
	slidesToScrollMobile?: number;
	subtitle?: string;
	title?: string;
	variation?: string;
}

export const CarouselDefault: FC<CarouselProps> = ({
	children,
	className,
	ctaLink,
	slides,
	slidesToScroll = 4,
	slidesToScrollMobile = 1,
	subtitle,
	title,
	variation,
}) => {
	const [carouselRef, carouselApi] = useEmblaCarousel({
		align: 'start',
		breakpoints: {
			'(min-width: 768px)': { loop: true, slidesToScroll },
		},
		loop: true,
		slidesToScroll: slidesToScrollMobile,
	});

	const {
		nextBtnDisabled,
		onNextButtonClick,
		onPrevButtonClick,
		prevBtnDisabled,
		selectedIndex,
		totalPages,
	} = useCarouselControls(carouselApi);

	const isGalleryCarousel = variation === 'gallery-carousel';
	const isArticleCarousel = variation === 'article-carousel';
	const previousLabel = isGalleryCarousel
		? 'Previous Image'
		: 'Previous Article';
	const nextLabel = isGalleryCarousel ? 'Next Image' : 'Next Article';

	const cx = clsx(styles.carousel, className, variation, 'alignfull', {
		[styles.carouselNoControls]: nextBtnDisabled && prevBtnDisabled,
	});

	return (
		<section className={cx}>
			<div className={styles.carouselContainer}>
				<div className={styles.carouselHeader}>
					<div className={styles.carouselContent}>
						{title && <h2 className={styles.carouselTitle}>{title}</h2>}
						{subtitle && <p className={styles.carouselSubtitle}>{subtitle}</p>}
					</div>
					<div className={styles.carouselControlsWrap}>
						<div className={styles.carouselControls}>
							<span className={styles.carouselPagination}>
								{selectedIndex + 1} / {totalPages}
							</span>
							<div className={styles.carouselButtons}>
								<Button
									disabled={prevBtnDisabled}
									label={previousLabel}
									onClick={onPrevButtonClick}
									variation="icon-large"
								>
									<ArrowLeft />
								</Button>
								<Button
									disabled={nextBtnDisabled}
									label={nextLabel}
									onClick={onNextButtonClick}
									variation="icon-large"
								>
									<ArrowRight />
								</Button>
							</div>
						</div>

						{ctaLink && isGalleryCarousel && (
							<SwipeGallery
								ctaClass="button button--secondary"
								ctaTitle={ctaLink.title}
								images={slides as SwipeGalleryImage[]}
							/>
						)}

						{ctaLink?.title && ctaLink.url && isArticleCarousel && (
							<Button
								{...ctaToButton(ctaLink)}
								className={styles.carouselControlsCta}
								variation="secondary"
							>
								{ctaLink.title}
							</Button>
						)}
					</div>
				</div>
				<div className="carousel">
					<div className="carousel__viewport" ref={carouselRef}>
						<div className="carousel__container">{children}</div>
					</div>
				</div>
			</div>
		</section>
	);
};
