'use client';

import { type FC, useMemo } from 'react';
import { NumbrixBoard } from '@/components/raven/Numbrix/NumbrixBoard';
import { NumbrixFooter } from '@/components/raven/Numbrix/NumbrixFooter';
import { NumbrixControls } from '@/components/raven/Numbrix/NumbrixControls';
import { NumbrixGameProvider } from '@/components/raven/Numbrix/NumbrixGameContext';
import type {
	NumbrixProps,
	NumbrixConfig,
} from '@/components/raven/Numbrix/definitions/NumbrixTypes';

import styles from './styles.module.css';

export const Numbrix: FC<NumbrixProps> = (props) => {
	const { data, date, flavor = 'Blueberry', level } = props;
	const solution = useMemo(() => {
		return data.split(',').map((item) => parseInt(item.trim(), 10)) || false;
	}, [data]);

	const numbrixConfig = useMemo((): false | NumbrixConfig => {
		if (!data) {
			return false;
		}

		if (!solution) {
			return false;
		}

		return {
			cellsPerRow: Math.sqrt(solution.length),
			data: data?.split(' ')[0] || '',
			date,
			flavor: flavor || '',
			level: level || '',
			maxCells: solution.length,
			solution,
		};
	}, [data, solution, flavor, level, date]);

	if (Math.sqrt(solution.length) % 1 !== 0) {
		return null;
	}

	if (!numbrixConfig) {
		return <div className="numbrix-error">Unable to display Numbrix</div>;
	}

	return (
		<NumbrixGameProvider config={numbrixConfig}>
			<div className={styles.numbrix}>
				<div className={styles.numbrixBoardWrapper}>
					<NumbrixBoard />
					<NumbrixControls />
				</div>
				<NumbrixFooter />
			</div>
		</NumbrixGameProvider>
	);
};
