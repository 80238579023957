import type { FC } from 'react';
import type { ReactComponentLike } from 'prop-types';
import styles from '@/components/raven/Numbrix/styles.module.css';
import { useNumbrixGame } from '@/components/raven/Numbrix/NumbrixGameContext';
import type { NumbrixGameStates } from '@/components/raven/Numbrix/definitions/NumbrixTypes';
import {
	Active,
	Paused,
	Solving,
	Waiting,
	Complete,
	Resigned,
	Resetting,
	ShowingDialog,
} from '@/components/raven/Numbrix/NumbrixStatus';

const GameState: Record<NumbrixGameStates, ReactComponentLike> = {
	Active,
	Complete,
	Paused,
	Resetting,
	Resigned,
	ShowingDialog,
	Solving,
	Waiting,
};
export const NumbrixBoardMsg: FC = () => {
	const {
		state: { gameState },
	} = useNumbrixGame();
	const Status = GameState[gameState];

	return (
		<div
			className={styles.numbrixBoardMsg}
			hidden={['Active', 'Solving'].includes(gameState)}
		>
			<Status />
		</div>
	);
};
