import type { ReactNode } from 'react';
import { isNonEmptyString } from 'tempest-common';
import { CommerceLink } from '@/components/Commerce/CommerceLink';
import {
	type CommerceItem,
	type CommerceOffer,
	identifyOfferStore,
	buildCommerceOfferId,
	type LinkEmbedConfig,
	type CommerceItemImage,
	buildCommerceImageSrcSet,
	type CommerceEmbedSharedOptions,
} from '@themaven-net/commerce-shared';

/**
 * Render a commerce link element using the supplied config
 *
 * This method allows for easy addition of defaults to config links
 */
export function renderCommerceLink(
	children: ReactNode,
	linkDestination: CommerceOffer | string | undefined,
	item?: CommerceItem,
	classes?: string,
	phxTrackId?: string,
	commerceConfig?: LinkEmbedConfig,
	href?: string,
	disableSkimlinks?: boolean,
	disableTrackonomics?: boolean,
): ReactNode {
	if (linkDestination === undefined && href === undefined) {
		return children;
	}

	return (
		<CommerceLink
			// eslint-disable-next-line react/no-children-prop
			children={children}
			className={classes ?? ''}
			commerceConfig={commerceConfig}
			commerceItem={item}
			commerceOffer={
				typeof linkDestination === 'string' ? undefined : linkDestination
			}
			disableSkimlinks={disableSkimlinks ?? true}
			disableTrackonomics={disableTrackonomics ?? false}
			href={href}
			linkTarget="_blank"
			phxTrackId={phxTrackId}
			rel="nofollow noreferrer"
		/>
	);
}

/**
 * Determine the proper button text for an offer
 *
 * This will also replace any data slugs in the text
 * @todo add dynamic token replacement here
 */
export function buildOfferButtonText(
	offer: CommerceOffer,
	options: CommerceEmbedSharedOptions,
	defaultButtonText: string,
): string {
	const offerId = buildCommerceOfferId(offer);
	let text = defaultButtonText;

	if (
		options.buttonTitleByIdentifier !== undefined &&
		isNonEmptyString(options.buttonTitleByIdentifier[offerId])
	) {
		text = options.buttonTitleByIdentifier[offerId];
	}

	return text;
}

export const getImageDetails = (image: CommerceItemImage) => {
	if (image.sizes !== undefined && image.sizes.length !== 0) {
		const srcSet = buildCommerceImageSrcSet(image);
		// Use the largest image as the "src" which will not be immediately displayed
		// due to the "srcset" placeholder but will be exposed for crawlers
		const { height, src, width } = image.sizes[image.sizes.length - 1];

		return { height, src, srcSet, width };
	}

	return { src: image.source };
};

/**
 * Information about a commerce offer that can be useful for rendering a CTA
 *
 * The text property is optional as it may not exist for a specific offer, and
 * should instead use the default language.
 */
export type CommerceButtonRenderDetails = {
	offer: CommerceOffer;
	text?: string;
};

/**
 * Build the button details for a single commerce offer based on the supplied options
 */
export function buildButtonDetails(
	offer: CommerceOffer,
	options: CommerceEmbedSharedOptions,
	defaultButtonText: string,
): CommerceButtonRenderDetails {
	return {
		offer,
		text: buildOfferButtonText(offer, options, defaultButtonText),
	};
}

/**
 * Render an individual offer with the "show price" option enabled
 *
 * @todo this will probably go away with the token replacement method
 * @returns TemplateResult for the offer.
 */
export function renderOfferWithPrice(offer: CommerceOffer): string | undefined {
	const vendorName = identifyOfferStore(offer);

	// // external without a defined store name, render the "without price" version.
	if (vendorName === undefined) {
		return undefined;
	}

	return `${offer.price} from ${vendorName}`;
}

export interface CommerceLinkRequestStatus {
	requestStatus: string;
}

export function isCommerceLinkRequestStatus(obj: unknown) {
	if (obj === null || typeof obj !== 'object') {
		return false;
	}

	const status = obj as CommerceLinkRequestStatus;

	return typeof status.requestStatus === 'string';
}
