const sharedSrcSet = ['(min-width: 1360px) 1360px', '352px'].join(', ');

export const imageSizes = {
	'50-50-media-bottom-media-left': sharedSrcSet,
	'50-50-media-bottom-media-right': sharedSrcSet,
	'50-50-media-top-media-left': sharedSrcSet,
	'50-50-media-top-media-right': sharedSrcSet,
	'50-50-stretch': [
		'(min-width: 790px) 375px',
		'(min-width: 768px) calc(50vw - 80px)',
		'calc(100vw - 24px)',
	].join(', '),
	'50-50-wide': [
		'(min-width: 1450px) 680px',
		'(min-width: 768px) calc(50vw - 80px)',
		'calc(100vw - 24px)',
	].join(', '),
	carousel: [
		'(min-width: 1400px) 316px',
		'(min-width: 1024px) calc(25vw - 24px)',
		'77vw',
	].join(', '),
	'list-media-left': '206px',
	'list-media-right': '206px',
	stacked: [
		'(min-width: 670px) 670px',
		'(min-width: 352px): 352px',
		'(min-width: 322px): 322px',
		'170px',
	].join(', '),
	'stacked-large': sharedSrcSet,
} as const;
