'use client';

import clsx from 'clsx';
import type { CtaProps } from '@/types/cta';
import type { FC, ElementType } from 'react';
import { Cta } from '@/components/raven/Cta';
import type { RavenIcon } from '@/utils/get-icons';
import useEmblaCarousel from 'embla-carousel-react';
import { decodeEntities } from '@/utils/post-helpers';
import { HtmlDecoder } from '@headstartwp/core/react';
import type { ArticleTileProps } from '@/types/articles';
import { InternalLink } from '@/components/InternalLink';
import { ArticleFeaturedImage } from '@/components/ArticleFeaturedImage';
import { useCarouselControls } from '@/utils/hooks/use-carousel-controls';
import { DotButton } from '@/components/raven/content/Carousels/Controls';

import styles from './styles.module.css';

const IMAGE_SIZES = [
	'(min-width: 1600px) 494px',
	'(min-width: 768px) calc(33vw - 60px)',
	'calc(71vw - 60px)',
].join(', ');

const renderCarouselSlide = (article: ArticleTileProps, index: number) => {
	const TitleTag = article.titleTag ?? 'h2';

	return (
		<div
			className={clsx(styles.carouselTilesSlide, 'carousel__slide')}
			key={index}
		>
			<figure className={styles.carouselTilesImageWrapper}>
				<ArticleFeaturedImage
					alt={article?.image?.alt ?? ''}
					aspectRatio="4:6"
					className={styles.carouselTilesImage}
					fill
					focalX={article?.image?.focalX}
					focalY={article?.image?.focalY}
					sizes={IMAGE_SIZES}
					src={`${article.image ? article?.image?.src : ''}`}
				/>
			</figure>
			<div className={styles.carouselTilesContent}>
				<TitleTag className={styles.carouselTilesSlideTitle}>
					<InternalLink
						className={styles.carouselTilesSlideLink}
						href={article.link}
					>
						{decodeEntities(article.title)}
					</InternalLink>
				</TitleTag>

				{article.author_name && (
					<div className={styles.carouselTilesAuthor}>
						{decodeEntities(article.author_name)}
					</div>
				)}
			</div>
		</div>
	);
};

interface CarouselTilesProps {
	cta?: CtaProps;
	ctaIcon?: RavenIcon;
	ctaStyle?: 'link' | 'primary' | 'secondary';
	posts: ArticleTileProps[];
	title?: string;
	titleLevel?: 1 | 2 | 3;
}

export const CarouselTiles: FC<CarouselTilesProps> = ({
	cta,
	ctaIcon,
	ctaStyle,
	posts,
	title,
	titleLevel = 2,
}) => {
	const [carouselRef, carouselApi] = useEmblaCarousel({
		align: 'start',
		loop: false,
		slidesToScroll: 1,
	});

	const { onDotButtonClick, scrollSnaps, selectedIndex } =
		useCarouselControls(carouselApi);

	if (!posts.length) {
		return null;
	}

	const TitleTag = `h${titleLevel}` as ElementType;
	const postTag = title ? 'h3' : null; // When the title is provided, the postTag should be a h3 to keep the hierarchy.

	return (
		<div className={styles.carouselTiles}>
			{title && (
				<TitleTag className={styles.carouselTilesTitle}>
					<HtmlDecoder html={title} />
				</TitleTag>
			)}

			<div className={styles.carouselTilesContainer}>
				<div className="carousel">
					<div className="carousel__viewport" ref={carouselRef}>
						<div className="carousel__container">
							{posts.map((article, index) => {
								const updatedArticle = {
									...article,
									titleTag: postTag,
								} as ArticleTileProps;

								return renderCarouselSlide(updatedArticle, index);
							})}
						</div>
					</div>
				</div>
				<div
					className={clsx(styles.carouselTilesControls, 'carousel__controls')}
				>
					<ul className="carousel__dots">
						{scrollSnaps.map((_, index) => (
							<li key={`dot-${scrollSnaps[index]}`}>
								<DotButton
									active={index === selectedIndex}
									aria-label={`Go to slide ${index + 1}`}
									onClick={() => onDotButtonClick(index)}
								/>
							</li>
						))}
					</ul>
				</div>
				{cta && (
					<Cta
						className={styles.carouselTilesCta}
						cta={cta}
						icon={ctaIcon}
						variation={ctaStyle}
					/>
				)}
			</div>
		</div>
	);
};
