'use client';

import { EmbedScriptsMap } from '@/constants';
import { useLoadScript } from '@/context/ScriptsContext';
import type { ScriptProps } from '@/context/ScriptsContext';
import { type FC, useState, type PropsWithChildren } from 'react';
import { EmbedFail } from '@/components/raven/Blocks/embed/EmbedFail';

type EmbedWrapperProps = {
	url: string;
} & PropsWithChildren &
	ScriptProps;

export const EmbedWrapper: FC<EmbedWrapperProps> = ({
	children,
	identifier,
	onAlreadyLoaded,
	onLoad,
	url,
}) => {
	const [isError, setIsError] = useState(false);

	useLoadScript(
		identifier,
		EmbedScriptsMap[identifier],
		onLoad,
		onAlreadyLoaded,
		() => setIsError(true),
	);

	if (isError) {
		return <EmbedFail identifier={identifier} url={url} />;
	}

	return children;
};
