import type { FC } from 'react';
import { Button } from '@/components/raven/ui/Button';
import styles from '@/components/Poll/styles.module.css';
import ArrowRight from '@themaven-net/raven-assets/ArrowRight';
import ShapeSquare from '@themaven-net/raven-assets/ShapeSquare';

const ONE_MINUTE_IN_MS = 1000 * 60;
const ONE_HOUR_IN_MS = 1000 * 60 * 60;
const ONE_DAY_IN_MS = ONE_HOUR_IN_MS * 24;

const getTimeLeftLabel = (isClosed: boolean, closedTime: string): string => {
	if (isClosed) {
		return 'Final Results';
	}

	const closedTimeTs = new Date(closedTime).getTime();
	const currentTime = Date.now();
	const timeLeftMs = closedTimeTs - currentTime;

	// Poll should be closed in this case, but accounting for it anyway.
	if (timeLeftMs <= 0) {
		return '0 Minutes Left';
	}

	if (timeLeftMs <= ONE_HOUR_IN_MS) {
		const timeLeftMinutes = Math.floor(timeLeftMs / ONE_MINUTE_IN_MS);

		return timeLeftMinutes === 1
			? `${timeLeftMinutes} Minute Left`
			: `${timeLeftMinutes} Minutes Left`;
	}

	if (timeLeftMs <= ONE_DAY_IN_MS) {
		const timeLeftHours = Math.floor(timeLeftMs / ONE_HOUR_IN_MS);

		return timeLeftHours === 1
			? `${timeLeftHours} Hour Left`
			: `${timeLeftHours} Hours Left`;
	}

	const timeLeftDays = Math.floor(timeLeftMs / ONE_DAY_IN_MS);

	return timeLeftDays === 1
		? `${timeLeftDays} Day Left`
		: `${timeLeftDays} Days Left`;
};

export const PollFooter: FC<{
	closedTime?: string;
	hasVoted: boolean;
	isClosed: boolean;
	selectedOption: null | string;
	voteCount: number;
}> = ({ closedTime, hasVoted, isClosed, selectedOption, voteCount }) => {
	const isOpen = !isClosed;

	return (
		<div className={styles.poll__footer}>
			{!isClosed && !hasVoted ? (
				<Button
					className={styles.poll__voteButton}
					disabled={selectedOption === null}
					onClick={() => {}}
					type="submit"
					variation="primary"
				>
					Vote
					<ArrowRight />
				</Button>
			) : null}
			<div className={styles.poll__voteCount}>
				<span>{voteCount} Votes</span>
				{isClosed || (isOpen && closedTime) ? (
					<>
						<div className={styles.poll__delimiterIcon}>
							<ShapeSquare />
						</div>
						<span>{getTimeLeftLabel(isClosed, closedTime || '0')}</span>
					</>
				) : null}
			</div>
		</div>
	);
};
