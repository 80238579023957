import styles from '@/components/raven/Numbrix/styles.module.css';

export const NumbrixFooter = () => (
	<div className={styles.numbrixFooter}>
		<div className={styles.numbrixFooterPanel}>
			<h2 className={styles.numbrixFooterTitle}>How to Play</h2>
			<p className={styles.numbrixFooterText}>
				The object of Numbrix is to fill the board with sequences of consecutive
				numbers from 1 to 81.
			</p>
			<p className={styles.numbrixFooterText}>
				The numbers must follow a horizontal or vertical path (no diagonals).
			</p>
			{/* TODO: Add Numbrix Help Video */}
		</div>
		<div className={styles.numbrixFooterPanel}>
			<h2 className={styles.numbrixFooterTitle}>About Numbrix</h2>
			<p className={styles.numbrixFooterText}>
				Want to exercise your brainpower and have fun at the same time? Play
				Numbrix, PARADE&#39;s new puzzle by Marilyn vos Savant. To solve it,
				complete a number matrix using logic and memory. No math or guessword is
				involved. Just fill in the puzzle so the consecutive numbers follow a
				horizontal or vertical path (no diagonals). Each puzzle has unique
				solutions.
			</p>
			<p className={styles.numbrixFooterText}>
				Numbrix was created by Marilyn vos Savant and has appeared in Parade
				Magazine since 2008. Marilyn vos Savant listed in the Guiness Book World
				Records Hall of Fame as the person with the highest IQ. She has has been
				writing the popular &#34;Ask Marilyn&#34; column for PARADE since 1986.
			</p>
		</div>
	</div>
);
