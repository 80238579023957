import type {
	AffiliateTagWithLevel,
	CommerceConfigContextValues,
} from '@/context/CommerceConfigContext';
import {
	LinkWrappers,
	type CommerceItem,
	type CommerceOffer,
	extractAsinFromUrl,
	type LinkWrapperMap,
	extractAscTagFromUrl,
	replaceAmazonTagInUrl,
	decorateUrlUsingMapper,
	buildMappedValueObject,
	type CommerceLinkWrapperFn,
	type CommerceKeyValueMapper,
	type CommerceLinkWrapperFnSet,
	type CommerceLinkWrapperConfig,
	type CommerceObjectValueMapper,
} from '@themaven-net/commerce-shared';

export type PermutiveCommerceAffiliateData = {
	affiliate?: string;
	campaign?: string;
	pageUrl: string;
	product: {
		categories?: Array<string>;
		name?: string;
		price?: {
			currency: string; // need clarification on the permitted values
			value: number;
		};
		productUrl: string;
	};
};
export function dataToProps(data: CommerceKeyValueMapper<string>) {
	return buildMappedValueObject(data);
}

export function trackonomicsKeyValueMapper(
	viewCorrelator: string,
	experiments: string,
) {
	return {
		exp: () => experiments,
		mcor: () => viewCorrelator,
		ref: () => document.referrer, // TODO: Referrer might change with Continuous Scroll
		utm_source: () =>
			new URLSearchParams(document.location.search).get('utm_source') || '',
	};
}

/**
 * Builds an object to send to permutive
 */
export function permutiveKeyValueMapper(
	trackedUri: string,
	commerceItem?: CommerceItem,
	primaryOffer?: CommerceOffer,
): CommerceObjectValueMapper<PermutiveCommerceAffiliateData> {
	return {
		pageUrl: () => window.location.toString(),
		product: () => {
			const built: PermutiveCommerceAffiliateData['product'] = {
				productUrl: trackedUri,
			};
			const item = commerceItem;
			const offer = primaryOffer;

			if (item) {
				built.name = item.title;
			}

			if (offer) {
				built.price = {
					currency: 'USD',
					value: Number(offer.price.substring(1)),
				};
			}

			return built;
		},
	};
}

/**
 * Swaps out the tag in a given url or adds one if no tag is present.
 * If the url already has a tag, then it just returns the original url.
 * It sorts the array of affiliateTags by precedence and
 * returns the tag with highest precedence.
 *
 * TODO: Move this to the commerce-api shared package
 * and update logic once we have post level tags
 * @param url
 * @param affiliateTags
 * @returns
 */
export function swapAffiliateTags(
	url: string,
	affiliateTags?: AffiliateTagWithLevel[],
) {
	if (affiliateTags && affiliateTags.length) {
		// Post/Page-level tags have precedence over site-level
		affiliateTags.sort((a, b) => b.level - a.level);
		return replaceAmazonTagInUrl(url, affiliateTags[0].value.tag);
	}
	return url;
}

function wrapTrackonomicsFn(
	viewCorrelator: string,
	experiments: string,
	affiliateTags?: AffiliateTagWithLevel[],
): CommerceLinkWrapperFn {
	return (url: string, wrapperDefinition: CommerceLinkWrapperConfig) => {
		const tagSwappedUrl = swapAffiliateTags(url, affiliateTags);
		const wrapped = LinkWrappers.Trackonomics(tagSwappedUrl, wrapperDefinition);
		return decorateUrlUsingMapper(
			wrapped,
			trackonomicsKeyValueMapper(viewCorrelator, experiments),
		);
	};
}

export function getLinkWrapperFns(
	viewCorrelator: string,
	experiments: string,
	affiliateTags?: AffiliateTagWithLevel[],
): CommerceLinkWrapperFnSet {
	return {
		Skimlinks: LinkWrappers.Skimlinks,
		Trackonomics: wrapTrackonomicsFn(
			viewCorrelator,
			experiments,
			affiliateTags,
		),
	};
}

/**
 * These are added as data attributes to the anchor
 */
export function getDynamicAttributesMapper(
	href: string,
): CommerceKeyValueMapper<string> {
	return {
		'data-aps-asc-tag': () => extractAscTagFromUrl(href), // @todo handle dynamic tag replacement
		'data-aps-asin': () => extractAsinFromUrl(href),
		'data-asin': () => extractAsinFromUrl(href),
	};
}

export function getLinkWrapperConfigMapper(
	commerceConfigContext: CommerceConfigContextValues,
	disableSkimlinks: boolean,
	disableTrackonomics: boolean,
): CommerceKeyValueMapper<CommerceLinkWrapperConfig> {
	return {
		Skimlinks: () =>
			disableSkimlinks ? undefined : commerceConfigContext.skimlinks,
		Trackonomics: () =>
			disableTrackonomics ? undefined : commerceConfigContext.trackonomics,
	};
}

export function getLinkWrapperConfigs(
	commerceConfigContext: CommerceConfigContextValues,
	linkWrappersByIdentifier: LinkWrapperMap,
	disableSkimlinks: boolean,
	disableTrackonomics: boolean,
): CommerceLinkWrapperConfig[] {
	const linkWrapperConfigs: CommerceLinkWrapperConfig[] = [];
	const configMapper = buildMappedValueObject(
		getLinkWrapperConfigMapper(
			commerceConfigContext,
			disableSkimlinks,
			disableTrackonomics,
		),
	);

	Object.values(linkWrappersByIdentifier).forEach((wrapperConfigs) => {
		wrapperConfigs.forEach((wrapperConfig) => {
			const mappedConfig = configMapper[wrapperConfig.vendor];
			if (mappedConfig !== undefined) {
				linkWrapperConfigs?.push(mappedConfig);
			}
		});
	});
	return linkWrapperConfigs;
}
