type PollBase = {
	closed?: boolean;
	closedTime?: string;
	description?: string;
	id: string;
	question: string;
};

type PollOptionBase = { id: string; score?: number; value: string };

export type PollApi = {
	options: {
		id: string;
		score?: number;
		value: PollOptionBase;
	}[];
	published: boolean;
} & PollBase;

export type PollDataOption = {
	isWinner?: boolean;
	percentage: string;
	score: number;
} & PollOptionBase;

export type PollData = {
	closed: boolean;
	description: string;
	options: PollDataOption[];
	totalVotes: number;
} & PollBase;

export const mapApiData = (pollApiData: null | PollApi) => {
	if (!pollApiData) {
		return null;
	}

	const voteCounts = pollApiData.options.map((option) => option.score || 0);
	const totalVotes = voteCounts.reduce(
		(sum, currentValue) => sum + currentValue,
		0,
	);
	const voteMax = Math.max(...voteCounts);

	const mappedData: PollData = {
		closed: !!pollApiData.closed,
		closedTime: pollApiData.closedTime,
		description: pollApiData.description || '',
		id: pollApiData.id,
		options: pollApiData.options.map((e): PollDataOption => {
			const score = e.score || 0;

			return {
				id: e.id,
				isWinner: pollApiData.closed && score === voteMax && voteMax > 0,
				percentage:
					totalVotes > 0 ? ((score / totalVotes) * 100).toFixed() : '0',
				score,
				value: e.value.value,
			};
		}),
		question: pollApiData.question,
		totalVotes,
	};

	return mappedData;
};
