'use client';

import clsx from 'clsx';
import Info from '@themaven-net/raven-assets/Info';
import { HtmlDecoder } from '@headstartwp/core/react';
import { type FC, useState, type ReactNode } from 'react';
import CloseRounded from '@themaven-net/raven-assets/CloseRounded';

import styles from './styles.module.css';

interface ImageCaptionProps {
	caption?: string;
	children?: ReactNode;
	sourceName?: string;
	sourceUrl?: string;
}

const ImageSource: FC<Omit<ImageCaptionProps, 'children'>> = ({
	sourceName,
	sourceUrl,
}) => {
	if (!sourceName) {
		return null;
	}

	let markup: ReactNode = sourceName;

	if (sourceUrl) {
		markup = (
			<a
				aria-label={`Go to image source: ${sourceName}`}
				href={sourceUrl}
				rel="noopener noreferrer"
				target="_blank"
			>
				{sourceName}
			</a>
		);
	}

	return <span>Source: {markup}</span>;
};

export const ImageCaption: FC<ImageCaptionProps> = ({
	caption,
	children,
	sourceName,
	sourceUrl,
}) => {
	const [visible, setVisible] = useState(false);
	if (!caption && !children && !sourceName) {
		return null;
	}
	const hasCaption = caption || children;

	const Icon = visible ? CloseRounded : Info;

	return (
		<>
			<div
				className={clsx(styles.imageCaption, {
					[styles.imageCaptionVisible]: visible,
				})}
			>
				<figcaption aria-hidden={!visible} className={styles.imageCaptionText}>
					{typeof caption === 'string' ? (
						<HtmlDecoder html={caption ?? ''} />
					) : (
						children
					)}
					{hasCaption && sourceName ? ' — ' : ' '}
					<ImageSource
						caption={caption}
						sourceName={sourceName}
						sourceUrl={sourceUrl}
					/>
				</figcaption>
			</div>

			<button
				aria-label={`${visible ? 'Hide' : 'Show'} caption`}
				className={styles.imageCaptionButton}
				onClick={() => setVisible(!visible)}
				type="button"
			>
				<span className={styles.imageCaptionIcon}>
					<Icon />
				</span>
			</button>
		</>
	);
};
