/**
 * List of arrow key names to make detection easier
 */
export const NumbrixArrowKeys = [
	'ArrowDown',
	'ArrowUp',
	'ArrowRight',
	'ArrowLeft',
];

/**
 * List of allowed "special" keys to make detection easier
 */
export const NumbrixSpecialKeys = ['Backspace', 'Delete', 'Tab'];

/**
 * List of number key names to make detection easier
 *
 * This could be accomplished using parseInt(keyEvent.key) !== NaN, but
 * this approach means that we can use the same type of detection as arrow
 * and special keys
 */
export const NumbrixNumberKeys = [
	'0',
	'1',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
];
