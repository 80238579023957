import type { FC } from 'react';
import Idea from '@themaven-net/raven-assets/Idea';
import Play from '@themaven-net/raven-assets/Play';
import Pause from '@themaven-net/raven-assets/Pause';
import Refresh from '@themaven-net/raven-assets/Refresh';
import Checkmark from '@themaven-net/raven-assets/Checkmark';
import styles from '@/components/raven/Numbrix/styles.module.css';
import {
	useNumbrixGame,
	formatElapsedTime,
} from '@/components/raven/Numbrix/NumbrixGameContext';

export const NumbrixControls: FC = () => {
	const {
		hasHint,
		requestHint,
		requestPause,
		requestRestart,
		requestResume,
		requestSolve,
		state: { elapsedTime, gameState },
	} = useNumbrixGame();
	const disableButtons =
		gameState === 'Solving' ||
		gameState === 'Resigned' ||
		gameState === 'Waiting';

	return (
		<div className={styles.numbrixControls}>
			<button
				aria-label={gameState === 'Paused' ? 'Resume' : 'Pause'}
				className={styles.numbrixButton}
				disabled={gameState === 'Solving' || gameState === 'Resigned'}
				onClick={
					gameState === 'Paused' || gameState === 'Waiting'
						? requestResume
						: requestPause
				}
				type="button"
			>
				{gameState === 'Paused' || gameState === 'Waiting' ? (
					<Play />
				) : (
					<Pause />
				)}
			</button>
			<button
				aria-label="Restart"
				className={styles.numbrixButton}
				disabled={gameState === 'Solving' || gameState === 'Waiting'}
				onClick={requestRestart}
				type="button"
			>
				<Refresh />
			</button>
			<div className={styles.numbrixTimer}>
				{formatElapsedTime(elapsedTime)}
			</div>
			<button
				aria-label="Hint"
				className={styles.numbrixButton}
				disabled={disableButtons || !hasHint}
				onPointerDown={requestHint}
				type="button"
			>
				<Idea />
			</button>
			<button
				aria-label="Solve"
				className={styles.numbrixButton}
				disabled={disableButtons}
				onClick={requestSolve}
				type="button"
			>
				<Checkmark />
			</button>
		</div>
	);
};
