import type { ReactNode } from 'react';
import { CommerceCta } from '@/components/raven/Commerce/CommerceCTA';
import type {
	CommerceItem,
	CommerceOffer,
	CommerceEmbedSharedOptions,
} from '@themaven-net/commerce-shared';

type CommerceButtonRenderDetails = {
	offer: CommerceOffer;
	text?: string;
};

export const renderCommerceCta = (
	offerDetails: CommerceButtonRenderDetails,
	item: CommerceItem,
	embedOptions?: CommerceEmbedSharedOptions,
	classes?: string,
	phxTrackId?: string,
	disableSkimlinks?: boolean,
	disableTrackonomics?: boolean,
): ReactNode => {
	if (offerDetails === undefined || offerDetails.text === undefined) {
		return undefined;
	}
	return (
		<CommerceCta
			className={classes ?? ''}
			config={{
				ctaText: offerDetails.text,
				item,
				offer: offerDetails.offer,
				options: embedOptions,
			}}
			disableSkimlinks={disableSkimlinks ?? true}
			disableTrackonomics={disableTrackonomics ?? false}
			phxTrackId={phxTrackId}
		/>
	);
};
