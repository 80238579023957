import clsx from 'clsx';
import type {
	NumbrixCellDefinition,
	NumbrixCellComponentRef,
} from '@/components/raven/Numbrix/definitions/NumbrixTypes';
import {
	NumbrixArrowKeys,
	NumbrixNumberKeys,
	NumbrixSpecialKeys,
} from '@/components/raven/Numbrix/definitions/NumbrixConstants';
import {
	useRef,
	useState,
	forwardRef,
	useCallback,
	useImperativeHandle,
	type FocusEventHandler,
	type KeyboardEventHandler,
} from 'react';

import styles from './styles.module.css';

interface NumbrixCellProps {
	gameActive: boolean;
	maxCells: number;
	onCellBlur?: (cell: NumbrixCellComponentRef) => void;
	onCellFocus?: (cell: NumbrixCellComponentRef) => void;
	onCellSolved?: (cell: NumbrixCellComponentRef) => void;
	onMove?: (direction: string, cell: NumbrixCellComponentRef) => void;
	options: NumbrixCellDefinition;
}

// Helper function to ensure type safety when checking answer
const getAnswer = (answer: number): false | number => answer || false;

export const NumbrixCell = forwardRef<
	NumbrixCellComponentRef,
	NumbrixCellProps
>(
	(
		{
			gameActive,
			maxCells,
			onCellBlur,
			onCellFocus,
			onCellSolved,
			onMove,
			options,
		},
		forwardedRef,
	) => {
		const [currentlyActive, setCurrentlyActive] = useState(false);
		const [isSolved, setIsSolved] = useState(false);
		const inputRef = useRef<HTMLInputElement>(null);
		const internalRef = useRef<null | NumbrixCellComponentRef>(null);

		// Utility functions
		const isFilledCorrectly = useCallback(() => {
			if (!inputRef.current) {
				return false;
			}

			const current = parseInt(inputRef.current.value, 10);
			return !Number.isNaN(current) && current === options.answer;
		}, [options.answer]);

		// Expose methods via ref
		useImperativeHandle(forwardedRef, () => {
			const ref: NumbrixCellComponentRef = {
				answer: getAnswer(options.answer),
				blur: () => inputRef.current?.blur(),
				cellIndex: options.cellIndex,
				filledCorrectly: () => isFilledCorrectly(),
				focus: () => inputRef.current?.focus(),
				isPrefilled: !!options.prefilled,
				markSolved: () => {
					if (!isFilledCorrectly() && inputRef.current) {
						inputRef.current.value = options.answer.toString();

						if (internalRef.current) {
							onCellSolved?.(internalRef.current);
						}
					}
					setIsSolved(true);
				},
				resetToDefault: () => {
					if (!inputRef.current) {
						return;
					}

					setCurrentlyActive(false);
					setIsSolved(false);
					inputRef.current.value = options.prefilled
						? options.answer.toString()
						: '';
					inputRef.current?.blur();
				},
				showHint: () => {
					if (!options.prefilled && inputRef.current) {
						inputRef.current.value = options.answer.toString();
					}
				},
			};
			internalRef.current = ref;
			return ref;
		}, [
			options.answer,
			options.cellIndex,
			options.prefilled,
			isFilledCorrectly,
			onCellSolved,
		]);

		// Event handlers
		const handleBlur: FocusEventHandler = (e) => {
			e.preventDefault();
			if (!gameActive) {
				return;
			}

			setCurrentlyActive(false);

			if (internalRef.current) {
				onCellBlur?.(internalRef.current);
			}
		};

		const handleFocus: FocusEventHandler = (e) => {
			e.preventDefault();
			if (!gameActive) {
				return;
			}

			setCurrentlyActive(true);

			if (internalRef.current) {
				onCellFocus?.(internalRef.current);
			}
		};

		const handleKeyDown: KeyboardEventHandler = (event) => {
			if (!gameActive) {
				event.preventDefault();
				return false;
			}

			if (event.key === 'Tab') {
				return true;
			}

			if (options.prefilled) {
				event.preventDefault();
				return false;
			}

			if ([...NumbrixNumberKeys, ...NumbrixSpecialKeys].includes(event.key)) {
				return true;
			}

			event.preventDefault();
			return false;
		};

		const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = (event) => {
			event.preventDefault();

			if (!gameActive) {
				return false;
			}

			if (NumbrixSpecialKeys.includes(event.key)) {
				return false;
			}

			if (NumbrixArrowKeys.includes(event.key)) {
				if (internalRef.current) {
					onMove?.(event.key.slice(5).toLowerCase(), internalRef.current);
				}
				return false;
			}

			return true;
		};

		const handleKeyPress: KeyboardEventHandler = (event) => {
			if (!gameActive) {
				event.preventDefault();
				return false;
			}

			if (
				NumbrixSpecialKeys.includes(event.key) ||
				NumbrixNumberKeys.includes(event.key)
			) {
				return true;
			}

			event.preventDefault();

			return false;
		};

		const handleChange = () => {
			if (isFilledCorrectly() && internalRef.current) {
				onCellSolved?.(internalRef.current);
			}
		};

		const rowSize = Math.sqrt(maxCells);
		const isLastRow = options.cellIndex >= maxCells - rowSize;
		const isFirstRow = options.cellIndex < rowSize;
		const isLastColumn = options.cellIndex % rowSize === rowSize - 1;
		const isFirstColumn = options.cellIndex % rowSize === 0;

		// Calculate classes using clsx
		const classes = clsx(styles.numbrixCell, {
			[styles.numbrixCellActive]: currentlyActive,
			[styles.numbrixCellBottom]: isLastRow,
			[styles.numbrixCellLeft]: isFirstColumn,
			[styles.numbrixCellPrefilled]: !!options.prefilled,
			[styles.numbrixCellRight]: isLastColumn,
			[styles.numbrixCellSolved]: isSolved,
			[styles.numbrixCellTop]: isFirstRow,
		});

		return (
			<div className={classes} id={`c${options.cellIndex}`}>
				<input
					autoComplete="off"
					className={styles.numbrixCellInput}
					defaultValue={options.prefilled ? options.answer.toString() : ''}
					disabled={!gameActive}
					id={`c_${options.cellIndex}`}
					maxLength={2}
					name={`c_${options.cellIndex}`}
					onBlur={handleBlur}
					onChange={handleChange}
					onFocus={handleFocus}
					onKeyDown={handleKeyDown}
					onKeyPress={handleKeyPress}
					onKeyUp={handleKeyUp}
					pattern="[0-9]*"
					ref={inputRef}
					type="text"
				/>
			</div>
		);
	},
);

NumbrixCell.displayName = 'NumbrixCell';
