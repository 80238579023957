'use client';

import type { FC, PropsWithChildren } from 'react';
import { EmbedWrapper } from '@/components/EmbedWrapper';

const onLoad = () => {
	window.instgrm?.Embeds?.process();
};

interface InstagramEmbedWrapperProps extends PropsWithChildren {
	url: string;
}

export const InstagramEmbedWrapper: FC<InstagramEmbedWrapperProps> = ({
	children,
	url,
}) => (
	<EmbedWrapper
		identifier="instagram"
		onAlreadyLoaded={onLoad}
		onLoad={onLoad}
		url={url}
	>
		{children}
	</EmbedWrapper>
);
