import {
	type CommerceOffer,
	OfferSelectionContainer,
} from '@themaven-net/commerce-shared';

export interface OfferSelectionConfig {
	preferredAffiliates?: string[];
	preferredMerchants?: string[];
}

export enum SelectOfferActionType {
	SET_OFFER = 'setOffer',
	UPDATE_OFFER_SELECTION = 'updateOfferSelection',
	UPDATE_OFFER_METADATA = 'updateOfferMetadata',
}

export interface SelectSingleOfferAction {
	payload: SelectSingleOfferState;
	type: SelectOfferActionType;
}

export interface SelectMultipleOffersAction {
	payload: SelectMultipleOffersState;
	type: SelectOfferActionType;
}

export interface SelectSingleOfferState {
	offer?: CommerceOffer;
	offerMetadata?: OfferSelectionConfig;
}

export interface SelectMultipleOffersState {
	offerMetadata?: OfferSelectionConfig;
	offers?: CommerceOffer[];
	primaryOffer?: CommerceOffer;
}

export function selectSingleOfferReducer(
	state: SelectSingleOfferState,
	action: SelectSingleOfferAction,
) {
	switch (action.type) {
		case SelectOfferActionType.SET_OFFER:
			return {
				...state,
				offer: action.payload.offer,
			};
		case SelectOfferActionType.UPDATE_OFFER_METADATA:
			return {
				...state,
				offerMetadata: action.payload.offerMetadata,
			};
		case SelectOfferActionType.UPDATE_OFFER_SELECTION: {
			const offerSelection = new OfferSelectionContainer(
				[action.payload.offer!],
				state.offerMetadata?.preferredMerchants ?? [],
				state.offerMetadata?.preferredAffiliates ?? [],
				1,
			);
			return {
				...state,
				offer: offerSelection.selectedOffers
					? offerSelection.selectedOffers[0]
					: offerSelection.primaryOffer,
			};
		}
		default:
			return state;
	}
}

export function selectMultipleOffersReducer(
	state: SelectMultipleOffersState,
	action: SelectMultipleOffersAction,
) {
	switch (action.type) {
		case SelectOfferActionType.SET_OFFER:
			return {
				...state,
				offers: action.payload.offers,
			};
		case SelectOfferActionType.UPDATE_OFFER_METADATA:
			return {
				...state,
				offerMetadata: action.payload.offerMetadata,
			};
		case SelectOfferActionType.UPDATE_OFFER_SELECTION: {
			const offerSelection = new OfferSelectionContainer(
				action.payload.offers!,
				state.offerMetadata?.preferredMerchants ?? [],
				state.offerMetadata?.preferredAffiliates ?? [],
				1,
			);
			return {
				...state,
				offers: offerSelection.selectedOffers,
				primaryOffer: offerSelection.primaryOffer,
			};
		}
		default:
			return state;
	}
}
