'use client';

import type {
	CommerceItem,
	CommerceOffer,
} from '@themaven-net/commerce-shared';
import {
	type FC,
	useMemo,
	useContext,
	createContext,
	type ReactNode,
} from 'react';

interface CommerceItemContextValues {
	commerceItem?: CommerceItem;
	commerceOffer?: CommerceOffer;
}

export const CommerceItemContext = createContext<
	CommerceItemContextValues | undefined
>(undefined);

export const useCommerceItem = () => {
	const context = useContext(CommerceItemContext);

	if (!context) {
		return {};
	}

	return context;
};

export const CommerceItemContextProvider: FC<{
	children: ReactNode;
	commerceItem?: CommerceItem;
	commerceOffer?: CommerceOffer;
}> = ({ children, commerceItem, commerceOffer }) => {
	return (
		<CommerceItemContext.Provider
			value={useMemo(
				() => ({ commerceItem, commerceOffer }),
				[commerceItem, commerceOffer],
			)}
		>
			{children}
		</CommerceItemContext.Provider>
	);
};
