'use client';

import clsx from 'clsx';
import { Image } from '@/components/raven/Image';
import { type FC, useState, useEffect } from 'react';
import { Label } from '@/components/raven/ui/Label/index';
import { useCommerceItem } from '@/context/CommerceItemContext';
import { useCommerceConfig } from '@/context/CommerceConfigContext';
import { renderCommerceCta } from '@/components/raven/Commerce/CommerceCTA/util';
import styles from '@/components/raven/Commerce/CommerceInfoblock/styles.module.css';
import {
	SelectOfferActionType,
	selectMultipleOffersReducer,
	type SelectMultipleOffersState,
} from '@/components/Commerce/reducers';
import {
	getImageDetails,
	buildButtonDetails,
	renderCommerceLink,
	renderOfferWithPrice,
	type CommerceButtonRenderDetails,
} from '@/utils/commerce/commerceUtils';
import {
	isCommerceOffer,
	type CommerceOffer,
	type CommerceItemImage,
	isCompleteCommerceItem,
	OfferSelectionContainer,
	type InfoblockEmbedConfig,
} from '@themaven-net/commerce-shared';

export interface CommerceInfoblockProps {
	badge?: string;
	buttonTextOverride?: string;
	className?: string;
	config?: InfoblockEmbedConfig;
	imageAttributionOverride?: string;
}

type ImageDetails = {
	height: number;
	src: string;
	srcSet: string;
	width: number;
};

export const CommerceInfoblock: FC<CommerceInfoblockProps> = ({
	badge,
	buttonTextOverride,
	className,
	config,
	imageAttributionOverride,
}) => {
	const commerceConfigContext = useCommerceConfig();
	const commerceItemContext = useCommerceItem();
	const item = config?.item ?? commerceItemContext.commerceItem;

	const selectOffers = (): CommerceOffer[] => {
		const offers = item?.offers ?? commerceItemContext.commerceItem?.offers;
		try {
			if (
				offers !== undefined &&
				offers.every((offer) => isCommerceOffer(offer))
			) {
				return offers;
			}

			if (isCompleteCommerceItem(item)) {
				const container = new OfferSelectionContainer(
					item?.offers ?? [],
					commerceConfigContext?.phxCommerce?.preferredMerchants ?? [],
					commerceConfigContext?.phxCommerce?.preferredAffiliates ?? [],
					3,
				);
				return container.selectedOffers ?? [];
			}
			return [];
		} catch (_e) {
			return [];
		}
	};

	const InitialOfferState: SelectMultipleOffersState = {
		offerMetadata: {
			preferredAffiliates:
				commerceConfigContext?.phxCommerce?.preferredAffiliates ?? [],
			preferredMerchants:
				commerceConfigContext?.phxCommerce?.preferredMerchants ?? [],
		},
		offers: selectOffers() ?? commerceItemContext?.commerceItem?.offers,
	};

	// Offer effect
	const [offerState, setOfferState] =
		useState<SelectMultipleOffersState>(InitialOfferState);

	useEffect(() => {
		if (offerState.primaryOffer === undefined) {
			const newOfferState = selectMultipleOffersReducer(offerState, {
				payload: { offers: offerState.offers },
				type: SelectOfferActionType.UPDATE_OFFER_SELECTION,
			});
			if (newOfferState.primaryOffer !== undefined) {
				setOfferState(newOfferState);
			}
		}
	}, [offerState.primaryOffer, offerState.offers, offerState]);

	const renderInfoblockImage = (
		image: CommerceItemImage,
		imageDetails?: ImageDetails,
	) => {
		const IMAGE_SIZES = [
			'(min-width: 1600px) 494px',
			'(min-width: 768px) calc(33vw - 60px)',
			'calc(71vw - 60px)',
		].join(', ');
		const imageAttribution = imageAttributionOverride;

		if (offerState.primaryOffer && image && isCompleteCommerceItem(item)) {
			return (
				<figure className={styles.productCardImageWrapper}>
					{badge && (
						<Label
							className={clsx(styles.productCardBadge, 'product-card__badge')}
							filled
							text={badge}
							variant="commerce"
						/>
					)}
					{imageDetails &&
						renderCommerceLink(
							<div
								className={clsx(
									styles.productCardImageContainer,
									'product-card__image_container',
								)}
							>
								<Image
									alt={image.alt ?? ''}
									aspectRatio="1:1"
									className={styles.productCardImage}
									fill
									sizes={IMAGE_SIZES}
									src={imageDetails.src}
								/>
							</div>,
							offerState.primaryOffer,
							item,
							'',
							'infoblock-image',
							config,
						)}
					{imageAttribution && (
						<figcaption className={styles.productCardAttribution}>
							{imageAttribution}
						</figcaption>
					)}
				</figure>
			);
		}
		return undefined;
	};

	if (offerState.primaryOffer !== undefined && isCompleteCommerceItem(item)) {
		const children = (
			<span className={clsx(styles.productCardTitle, 'product-card__title')}>
				{config?.title || item.title}
			</span>
		);

		let buttonDetails: CommerceButtonRenderDetails | undefined;
		const defaultButtonText = buttonTextOverride || 'Shop It';
		if (config?.showPrice && config.options) {
			buttonDetails = buildButtonDetails(
				offerState.primaryOffer,
				config.options,
				renderOfferWithPrice(offerState.primaryOffer) || defaultButtonText,
			);
		} else {
			buttonDetails = config?.options
				? buildButtonDetails(
						offerState.primaryOffer,
						config.options,
						defaultButtonText,
					)
				: undefined;
		}

		const image = config?.options?.imageOverrides?.[0] || item.images[0];

		return (
			<div className={clsx(className, styles.productCard, 'product-card')}>
				{image &&
					renderInfoblockImage(image, getImageDetails(image) as ImageDetails)}

				<div className={clsx(styles.productCardInfo, 'product-card__info')}>
					<p className={clsx(styles.productCardTitle, 'product-card__title')}>
						{renderCommerceLink(
							children,
							offerState.primaryOffer,
							item,
							styles.productCardTitleLink,
							'infoblock-title',
							config,
						)}
					</p>
					{config?.description && (
						<span className={styles.productCardDescription}>
							{config?.description}
						</span>
					)}
					{buttonDetails &&
						renderCommerceCta(
							buttonDetails,
							item,
							config?.options,
							`button ${styles.productCardButton}`,
							'infoblock-button',
						)}
				</div>
			</div>
		);
	}

	return undefined;
};
