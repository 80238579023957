'use client';

import { type FC, useState, useEffect } from 'react';
import { useCommerceItem } from '@/context/CommerceItemContext';
import { useCommerceConfig } from '@/context/CommerceConfigContext';
import {
	renderCommerceLink,
	buildOfferButtonText,
} from '@/utils/commerce/commerceUtils';
import {
	SelectOfferActionType,
	selectMultipleOffersReducer,
	type SelectMultipleOffersState,
} from '@/components/Commerce/reducers';
import {
	type CommerceOffer,
	buildExternalOffer,
	type CtaEmbedConfig,
	isCompleteCommerceItem,
	OfferSelectionContainer,
} from '@themaven-net/commerce-shared';

export interface CommerceCtaProps {
	buttonTextOverride?: string;
	className?: string;
	config: CtaEmbedConfig;
	disableSkimlinks: boolean;
	disableTrackonomics: boolean;
	href?: string;
	phxTrackId?: string;
}

export const CommerceCta: FC<CommerceCtaProps> = ({
	buttonTextOverride,
	className,
	config,
	disableSkimlinks,
	disableTrackonomics,
	href,
	phxTrackId,
}) => {
	const commerceConfigContext = useCommerceConfig();
	const commerceItemContext = useCommerceItem();

	const selectOffers = (): CommerceOffer[] => {
		const offer = config?.offer ?? commerceItemContext.commerceOffer;
		if (offer !== undefined) {
			return [offer];
		}

		try {
			if (config?.link !== undefined) {
				return [buildExternalOffer(config.link)];
			}

			if (isCompleteCommerceItem(config?.item)) {
				const container = new OfferSelectionContainer(
					config.item?.offers ?? [],
					commerceConfigContext?.phxCommerce?.preferredMerchants ?? [],
					commerceConfigContext?.phxCommerce?.preferredAffiliates ?? [],
					3,
				);
				return container.selectedOffers ?? [];
			}
			return [];
		} catch (_e) {
			return [];
		}
	};

	const InitialOfferState: SelectMultipleOffersState = {
		offerMetadata: {
			preferredAffiliates:
				commerceConfigContext?.phxCommerce?.preferredAffiliates ?? [],
			preferredMerchants:
				commerceConfigContext?.phxCommerce?.preferredMerchants ?? [],
		},
		offers: selectOffers(),
	};
	// Offer effect
	const [offerState, setOfferState] =
		useState<SelectMultipleOffersState>(InitialOfferState);

	useEffect(() => {
		if (offerState.offers && offerState.primaryOffer === undefined) {
			const newOfferState = selectMultipleOffersReducer(offerState, {
				payload: { offers: offerState.offers },
				type: SelectOfferActionType.UPDATE_OFFER_SELECTION,
			});
			if (newOfferState.primaryOffer !== undefined) {
				setOfferState(newOfferState);
			}
		}
	}, [offerState, offerState.offers, offerState.primaryOffer]);

	const getButtonText = (): string => {
		const defaultButtonText = buttonTextOverride ?? `Shop It`;

		if (config?.ctaText !== undefined && config?.ctaText !== '') {
			// we should not do text replacement on manually specified text
			return config.ctaText;
		}
		if (offerState.primaryOffer) {
			return buildOfferButtonText(
				offerState.primaryOffer,
				config?.options?.buttonTitleByIdentifier ?? {},
				defaultButtonText,
			);
		}
		return defaultButtonText;
	};

	try {
		const { primaryOffer } = offerState;
		if (
			config === undefined ||
			(primaryOffer === undefined && href === undefined)
		) {
			return undefined;
		}
		const commerceItem = config.item ?? commerceItemContext.commerceItem;
		const buttonText = getButtonText();
		const item = isCompleteCommerceItem(commerceItem)
			? commerceItem
			: undefined;
		return renderCommerceLink(
			buttonText,
			primaryOffer,
			item,
			className,
			phxTrackId,
			config,
			href,
			disableSkimlinks,
			disableTrackonomics,
		);
	} catch (e) {
		return undefined;
	}
};
