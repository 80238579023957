'use client';

import clsx from 'clsx';
import type { FC } from 'react';
import type { CtaProps } from '@/types/cta';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import { decodeEntities } from '@/utils/post-helpers';
import { LinkWrapper } from '@/components/LinkWrapper';
import type { ArticleTileProps } from '@/types/articles';
import { InternalLink } from '@/components/InternalLink';
import CaretOpen from '@themaven-net/raven-assets/CaretOpen';
import { ArticleFeaturedImage } from '@/components/ArticleFeaturedImage';
import { DotButton } from '@/components/raven/content/Carousels/Controls';
import { useCarouselControls } from '@/utils/hooks/use-carousel-controls';

import styles from './styles.module.css';

const IMAGE_SIZE = ['(min-width: 1520px) 1520px', '100vw'].join(', ');

interface HeroSlideProps {
	active: boolean;
	article: ArticleTileProps;
	index: number;
}

const HeroSlide: FC<HeroSlideProps> = ({ active, article, index }) => {
	const TitleTag = article.titleTag ?? 'h2';
	const hasImage = article.image && article.image.src;
	const cx = clsx(styles.carouselHeroSlide, 'carousel__slide', {
		[styles.carouselHeroSlideActive]: active,
	});

	return (
		<div className={cx}>
			{hasImage && (
				<figure className={styles.carouselHeroImageWrapper}>
					<ArticleFeaturedImage
						alt={article.image?.alt ?? ''}
						className={styles.carouselHeroImage}
						fill
						focalX={article.image?.focalX}
						focalY={article.image?.focalY}
						priority={index === 0}
						sizes={IMAGE_SIZE}
						src={`${article.image ? article?.image?.src : ''}`}
					/>
				</figure>
			)}

			<div className={styles.carouselHeroContent}>
				<TitleTag className={styles.carouselHeroSlideTitle}>
					<InternalLink
						aria-hidden={!active}
						className={styles.carouselHeroSlideLink}
						href={article.link}
						tabIndex={active ? 0 : -1}
					>
						{decodeEntities(article.title)}
					</InternalLink>
				</TitleTag>
			</div>
		</div>
	);
};

interface CarouselHeroProps {
	autoRotate?: boolean;
	cta?: CtaProps;
	delay?: number;
	posts: ArticleTileProps[];
}

export const CarouselHero: FC<CarouselHeroProps> = ({
	autoRotate = false,
	cta,
	delay = 5,
	posts,
}) => {
	const cx = clsx(styles.carouselHero, 'is-dark avoid-margin');

	const [carouselRef, carouselApi] = useEmblaCarousel(
		{
			align: 'start',
			loop: true,
			slidesToScroll: 1,
			startIndex: 0,
		},
		[Autoplay({ delay: delay * 1000, playOnInit: autoRotate })],
	);

	const { onDotButtonClick, scrollSnaps, selectedIndex } =
		useCarouselControls(carouselApi);

	if (!posts.length) {
		return null;
	}

	return (
		<div className={cx}>
			<div className={styles.carouselHeroWrapper}>
				<div className="carousel">
					<div className="carousel__viewport" ref={carouselRef}>
						<div
							className={clsx(
								styles.carouselHeroWrapperContainer,
								'carousel__container',
							)}
						>
							{posts.map((article, index) => (
								<HeroSlide
									active={index === selectedIndex}
									article={article}
									index={index}
									key={article.id}
								/>
							))}
						</div>
					</div>

					<div
						className={clsx(styles.carouselHeroControls, 'carousel__controls')}
					>
						<ul className="carousel__dots">
							{scrollSnaps.map((_, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<li key={`dot-${scrollSnaps[index]}-${index}`}>
									<DotButton
										active={index === selectedIndex}
										aria-label={`Go to slide ${index + 1}`}
										onClick={() => onDotButtonClick(index)}
									/>
								</li>
							))}
						</ul>
					</div>
				</div>
				{cta && cta.url && cta.title && (
					<div className={styles.carouselHeroCta}>
						<LinkWrapper
							className={styles.carouselHeroCtaButton}
							href={cta.url}
						>
							{cta.title}
							<CaretOpen height={12} width={12} />
						</LinkWrapper>
					</div>
				)}
			</div>
		</div>
	);
};
